import {
    Box,
    Flex,
    Stack,
    Text,
    useToast
  } from "@chakra-ui/react";
  
  import { FormEvent, useState } from 'react';
  import { useHistory, useLocation } from 'react-router-dom';
  import HttpClient from '../helpers/HttpClient';
  import { ResponseHelper } from '../helpers/interfaces/ResponseHelper';
  import { AxiosResponse } from 'axios';
  import LoginLayout from '../components/LoginLayout';
  import { WidePrimaryButton } from "../components/buttons";
  import { CombineInput } from "../components/inputs";
  
  export interface SetPasswordPagePagePrams {
      email: string;
  }
  
  const SetPasswordPage: React.FC = () => {
  
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const toast = useToast();
    const email = (new URLSearchParams(location.search)).get('email')
  
  
  
    const submit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsLoading(true);
      const myToast = (description: string, isSuccessful: boolean) => {
        toast({
          description,
          status: isSuccessful ? "success" : "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      }
      // make Api Call
      (async () => {
        try {
          await HttpClient.put<AxiosResponse<ResponseHelper<null>>>('admin/reset/password/confirm', { token, password, login_email: email });
          history.push('/');
        } catch (error) {
          // notify user of error
          myToast(error?.response?.data?.message || "Unknown Error", false);
          setIsLoading(false);
        }
      })()
    };
  
    return (
      <LoginLayout>
        <Flex justifyContent="space-between" align="center">
          <Box color="brand.primary">
            <Text fontSize="7xl"  fontWeight="900" style={{lineHeight: 1.2}}>Set a new<br /> password</Text>
            <Text as="p" fontWeight="400" >To set a new password, enter verification<br /> code we sent to <b>{email ?? ''}</b></Text>
          </Box>
          <Box bg="white" width="513px" py="50px" px="40px" border="1px" borderColor="#DEEAFA" borderRadius="10px">
            <form onSubmit={submit}>
              <Stack
                spacing={4}
              >
                <CombineInput label="Confirmation Code" placeholder="Enter Confirmation Code" onChange={(e) => setToken(e.target.value)} />
                <CombineInput label="Password" placeholder="Min 8 characters" onChange={(e) => setPassword(e.target.value)} isPassword />
                <Flex justifyContent="flex-end">
                  <WidePrimaryButton
                    label="Confirm Password"
                    height="50px"
                    onClick={() => {}}
                    isLoading={isLoading}
                    type="submit" />
                </Flex>
              </Stack>
            </form>
          </Box>
        </Flex>
      </LoginLayout>
    );
  };
  
  export default SetPasswordPage;
  