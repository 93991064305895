export const Polygon = () => (
  <svg
    width="9"
    height="23"
    viewBox="0 0 9 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.499161 12.3661C0.207286 12.001 0.207287 11.4824 0.499161 11.1173L9 0.483337L9 23L0.499161 12.3661Z"
      fill="#F9F9F9"
    />
  </svg>
)

export const NotificationBell = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.50083 14.7871V14.5681C3.53295 13.9202 3.7406 13.2925 4.10236 12.7496C4.7045 12.0975 5.1167 11.2983 5.29571 10.436C5.29571 9.7695 5.29571 9.0935 5.35393 8.42703C5.65469 5.21842 8.82728 3 11.9611 3H12.0387C15.1725 3 18.345 5.21842 18.6555 8.42703C18.7137 9.0935 18.6555 9.7695 18.704 10.436C18.8854 11.3003 19.2972 12.1019 19.8974 12.7591C20.2618 13.2972 20.4698 13.9227 20.4989 14.5681V14.7776C20.5206 15.648 20.2208 16.4968 19.6548 17.1674C18.907 17.9515 17.8921 18.4393 16.8024 18.5384C13.607 18.8812 10.383 18.8812 7.18762 18.5384C6.09914 18.435 5.08576 17.9479 4.33521 17.1674C3.778 16.4963 3.48224 15.6526 3.50083 14.7871Z"
      stroke="#08235B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.55469 21.8518C10.054 22.4785 10.7871 22.884 11.592 22.9788C12.3968 23.0735 13.2069 22.8495 13.843 22.3564C14.0387 22.2106 14.2147 22.041 14.3669 21.8518"
      stroke="#08235B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="17" cy="4" r="4" fill="#00973D" />
  </svg>
)

export const RiseIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66242 2.89239L6.66242 11.0071L5.32929 11.0071L5.32882 2.89286L1.75321 6.46846L0.810406 5.52565L5.99586 0.340204L11.1813 5.52565L10.2385 6.46846L6.66242 2.89239Z"
      fill="#3AA674"
    />
  </svg>
)

export const FallIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.32977 9.12153L5.32977 1.00677L6.6629 1.00677L6.66337 9.12106L10.239 5.54545L11.1818 6.48826L5.99633 11.6737L0.810883 6.48826L1.75369 5.54545L5.32977 9.12153Z"
      fill="#F42C1F"
    />
  </svg>
)

export const MessageIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5111 6.04077L8.66745 8.33029C8.12931 8.7522 7.37493 8.7522 6.83679 8.33029L3.96875 6.04077"
      stroke="#172B4D"
      strokeOpacity="0.7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.59159 2.33337H10.8769C11.7831 2.34354 12.6457 2.72666 13.2637 3.39351C13.8817 4.06036 14.2011 4.95273 14.1477 5.86279V10.2147C14.2011 11.1248 13.8817 12.0171 13.2637 12.684C12.6457 13.3508 11.7831 13.7339 10.8769 13.7441H4.59159C2.64498 13.7441 1.33301 12.1605 1.33301 10.2147V5.86279C1.33301 3.91701 2.64498 2.33337 4.59159 2.33337Z"
      stroke="#172B4D"
      strokeOpacity="0.7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const SuitcaseIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.86914 10.3179C1.86914 10.3179 1.96381 11.4765 1.98581 11.8419C2.01514 12.3319 2.20447 12.8792 2.52047 13.2592C2.96647 13.7979 3.49181 13.9879 4.19314 13.9892C5.01781 13.9905 11.0145 13.9905 11.8391 13.9892C12.5405 13.9879 13.0658 13.7979 13.5118 13.2592C13.8278 12.8792 14.0171 12.3319 14.0471 11.8419C14.0685 11.4765 14.1631 10.3179 14.1631 10.3179"
      stroke="#172B4D"
      strokeOpacity="0.7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.66406 3.55295V3.30562C5.66406 2.49228 6.32273 1.83362 7.13606 1.83362H8.8574C9.67006 1.83362 10.3294 2.49228 10.3294 3.30562L10.3301 3.55295"
      stroke="#172B4D"
      strokeOpacity="0.7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99642 11.1188V10.2561"
      stroke="#172B4D"
      strokeOpacity="0.7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.83301 5.59274V7.90408C3.11167 8.74741 4.64367 9.33808 6.32501 9.57208C6.52634 8.83808 7.18834 8.30008 7.99301 8.30008C8.78501 8.30008 9.46034 8.83808 9.64834 9.57874C11.3363 9.34474 12.8743 8.75408 14.1597 7.90408V5.59274C14.1597 4.46341 13.251 3.55408 12.1217 3.55408H3.87767C2.74834 3.55408 1.83301 4.46341 1.83301 5.59274Z"
      stroke="#172B4D"
      strokeOpacity="0.7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const CloseIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="13" fill="#172B4D" fillOpacity="0.1" />
    <path
      d="M16.8996 9.09972L9.09961 16.8997"
      stroke="#172B4D"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.8996 16.8997L9.09961 9.09972"
      stroke="#172B4D"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const RadioSelected = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="9" stroke="#0E62FF" strokeWidth="2" />
    <ellipse cx="10" cy="9.99902" rx="6" ry="6" fill="#0E62FF" />
  </svg>
)

export const RadioUnselected = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="9" stroke="#C4C4C4" strokeWidth="2" />
  </svg>
)

export const ActivitiesIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.91414 6.80127V11.3747"
      stroke="#00973D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.02547 4.61279V11.3746"
      stroke="#00973D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.085 9.21826V11.3749"
      stroke="#00973D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1235 1.3335H4.87587C2.69809 1.3335 1.33301 2.87489 1.33301 5.05693V10.9434C1.33301 13.1254 2.69174 14.6668 4.87587 14.6668H11.1235C13.3076 14.6668 14.6663 13.1254 14.6663 10.9434V5.05693C14.6663 2.87489 13.3076 1.3335 11.1235 1.3335Z"
      stroke="#00973D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
