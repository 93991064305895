import * as React from "react"
import moment from "moment"
import {
  HStack,
  GridItem,
  Text,
  Grid,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Avatar,
  VStack,
  useToast,
} from "@chakra-ui/react"
import { SolidButton, NakedButton } from "../../buttons"
import { MessageIcon, SuitcaseIcon } from "../../icons"
import Modal from "../../modal"
import { AxiosResponse } from "axios"
import HttpClient from "../../../helpers/HttpClient"
import { ResponseHelper } from "../../../helpers/interfaces/ResponseHelper"
import { DataRequest } from "../../../helpers/interfaces/DataRequest"
// import { User } from "../../../helpers/interfaces/User"
import {
  RequestInfo,
  PersonalInfo,
  Education,
  ProfessionalQualification,
} from "../../modalTabContent"

import { CustomTextarea } from "../../customInputs"

const environ = process.env.NODE_ENV
export interface RequestCardProps {
  dataRequest: DataRequest
  fetchAllRequests: () => void
  dataRequestOverview: () => void
}

const RequestCard: React.FC<RequestCardProps> = ({
  dataRequest,
  fetchAllRequests,
  dataRequestOverview,
}) => {
  const toast = useToast()
  const [appUrl, setAppUrl] = React.useState("")
  const [declineReason, setDeclineReason] = React.useState("")

  // modals
  const [showDeclineModal, setShowDeclineModal] = React.useState(false)
  const [viewRequestModal, setViewRequestModal] = React.useState(false)

  // methods
  const handleApproval = async (request: any, username: string) => {
    const { data } = await HttpClient.put<AxiosResponse<ResponseHelper<{}>>>(
      `admin/data-request/approve/${request.id}`,
      {}
    )

    if (data?.success) {
      toast({
        description: `You have approved ${username}'s data request`,
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      })
      setViewRequestModal(false)
      fetchAllRequests()
      dataRequestOverview()
    } else {
      toast({
        description: `Error approving ${username}'s data request`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  const handleDecline = async (request: any, username: string) => {
    if (!declineReason || declineReason === "") {
      toast({
        description: `Decline reason is required`,
        status: "info",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      })
      return
    }

    const { data } = await HttpClient.put<AxiosResponse<ResponseHelper<any>>>(
      `admin/data-request/decline/${request.id}`,
      { decline_reason: declineReason }
    )

    if (data?.success) {
      toast({
        description: `You have declined ${username}'s data request`,
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      })
      setShowDeclineModal(false)
      fetchAllRequests()
      dataRequestOverview()
    } else {
      toast({
        description: `Error declining ${username}'s data request`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      })
    }
  }

  // styles
  const tabFocusStyle = { outline: "none" }
  const tabSelectedStyle = {
    borderColor: "#0E62FF",
    borderBottomWidth: "3px",
    color: "#0E62FF",
  }
  const tabStyle = {
    paddingRight: 0,
    paddingLeft: 0,
    marginRight: 32,
    paddingBottom: 10,
    fontWeight: 500,
  }
  const tabPanelStyle = {
    paddingRight: 0,
    paddingLeft: 0,
  }

  React.useEffect(() => {
    environ === "production"
      ? setAppUrl("https://api.adein.cchub.org")
      : setAppUrl("https://api.adein.cchub.xyz")
  }, [])

  return (
    <>
      <GridItem
        background='white'
        rounded={10}
        padding='30px'
        colSpan={1}
        border='1px solid rgba(103, 121, 142, 0.1)'
      >
        <HStack spacing={10} alignItems='flex-end'>
          <HStack>
            <Avatar
              color='rgba(23, 43, 77, 0.5)'
              background='rgba(23, 43, 77, 0.1)'
              size='md'
              name={`${dataRequest.user.first_name} ${dataRequest.user.last_name}`}
              src={`${appUrl}/${dataRequest.user.avatar}`}
            />
            <Box>
              <Text
                color='rgba(23, 43, 77, 0.9)'
                fontSize={14}
                fontWeight='600'
                marginBottom={2}
              >
                {dataRequest.user.first_name} {dataRequest.user.last_name}
              </Text>
              <HStack
                color='rgba(23, 43, 77, 0.7)'
                fontSize={14}
                fontWeight='500'
              >
                <MessageIcon /> <Text>{dataRequest.user.login_email}</Text>
              </HStack>
            </Box>
          </HStack>
          <HStack color='rgba(23, 43, 77, 0.7)' fontSize={14} fontWeight='500'>
            <SuitcaseIcon /> <Text>{dataRequest.user.current_occupation}</Text>
          </HStack>
        </HStack>
        <HStack marginTop='30px'>
          <VStack spacing={0} width='50%' alignItems='flex-start'>
            <Text fontWeight='500' fontSize={14} color='#091E42'>
              Data Requested
            </Text>
            <Text fontWeight='300' fontSize={16}>
              {dataRequest.data}
            </Text>
          </VStack>
          <VStack spacing={0} width='50%' alignItems='flex-start'>
            <Text fontWeight='500' fontSize={14} color='#091E42'>
              Date requested
            </Text>
            <Text fontWeight='300' fontSize={16}>
              {moment(dataRequest.created_at).format("DD MMMM YYYY")}
            </Text>
          </VStack>
        </HStack>

        <VStack
          marginTop='20px'
          spacing={0}
          width='65%'
          alignItems='flex-start'
        >
          <Text fontWeight='500' fontSize={14} color='#091E42'>
            Reason For Data Request
          </Text>
          <Text fontWeight='300' fontSize={16}>
            {dataRequest.reason}
          </Text>
        </VStack>

        {/* actions */}
        <HStack justifyContent='space-between' marginTop={5}>
          <NakedButton
            textColor='#0E62FF'
            label='View Profile'
            onClick={() => setViewRequestModal(true)}
          />

          <HStack spacing={8}>
            <NakedButton
              textColor='#E0180B'
              label='Decline'
              onClick={() => setShowDeclineModal(true)}
            />
            <SolidButton
              backgroundColor='rgba(0, 151, 61, 0.9)'
              hoverBackgroundColor='rgba(0, 151, 61, 0.8)'
              label='Approve'
              onClick={() =>
                handleApproval(
                  dataRequest,
                  `${dataRequest.user.first_name} ${dataRequest.user.first_name}`
                )
              }
              height='30px'
            />
          </HStack>
        </HStack>
      </GridItem>

      {/* DECLINE MODAL */}
      <Modal
        width='439px'
        isOpen={showDeclineModal}
        close={() => setShowDeclineModal(false)}
        title='Decline Request'
        closableOutside={false}
      >
        <Box marginY={5}>
          <Text fontSize={16} fontWeight='300'>
            Are you sure you want to decline the data request from
            <Text marginLeft={1} display='inline' fontWeight='500'>
              {`${dataRequest.user?.first_name} ${dataRequest.user?.last_name}?`}
            </Text>
          </Text>
        </Box>
        <Box>
          <CustomTextarea
            handleInput={(value: string) => setDeclineReason(value)}
            value={declineReason}
            width='100%'
            label=''
            placeholder='Enter reason...'
            inputType='text'
            required={false}
          />
        </Box>
        <HStack marginTop={5} justifyContent='space-between'>
          <NakedButton
            textColor='#0E62FF'
            label='Cancel'
            onClick={() => setShowDeclineModal(false)}
          />
          <SolidButton
            height='40px'
            backgroundColor='rgba(224, 24, 11, 0.9)'
            hoverBackgroundColor='rgba(224, 24, 11, 0.8)'
            label='Decline'
            onClick={() =>
              handleDecline(
                dataRequest,
                `${dataRequest.user?.first_name} ${dataRequest.user?.first_name}`
              )
            }
          />
        </HStack>
      </Modal>

      {/* VIEW REQUEST MODAL */}
      <Modal
        width='639px'
        isOpen={viewRequestModal}
        close={() => setViewRequestModal(false)}
        title="User's Profile"
        closableOutside={false}
      >
        <Box marginY={5}>
          <HStack marginY={8}>
            <Avatar
              color='rgba(23, 43, 77, 0.5)'
              background='rgba(23, 43, 77, 0.1)'
              size='md'
              name={`${dataRequest.user.first_name} ${dataRequest.user.last_name}`}
              src={`${appUrl}/${dataRequest.user.avatar}`}
            />
            <VStack alignItems='flex-start' fontSize={15} spacing={0}>
              <Text color='rgba(23, 43, 77, 0.9)' fontWeight='600'>
                {dataRequest.user.first_name} {dataRequest.user.last_name}
              </Text>
              <Text color='rgba(23, 43, 77, 0.7)' fontWeight='500'>
                {dataRequest.user.login_email}
              </Text>
            </VStack>
          </HStack>
          <Tabs>
            <TabList>
              <Tab
                _focus={tabFocusStyle}
                _selected={tabSelectedStyle}
                style={tabStyle}
              >
                Request
              </Tab>
              <Tab
                _focus={tabFocusStyle}
                _selected={tabSelectedStyle}
                style={tabStyle}
              >
                Personal Info
              </Tab>
              <Tab
                _focus={tabFocusStyle}
                _selected={tabSelectedStyle}
                style={tabStyle}
              >
                Education
              </Tab>
              <Tab
                _focus={tabFocusStyle}
                _selected={tabSelectedStyle}
                style={tabStyle}
              >
                Professional Qualifications
              </Tab>
            </TabList>

            <TabPanels paddingTop={5}>
              <TabPanel style={tabPanelStyle}>
                <RequestInfo
                  user={dataRequest.user}
                  dataRequest={dataRequest}
                />
              </TabPanel>
              <TabPanel style={tabPanelStyle}>
                <PersonalInfo
                  user={dataRequest.user}
                  // dataRequest={dataRequest}
                />
              </TabPanel>
              <TabPanel style={tabPanelStyle}>
                <Education
                  user={dataRequest.user}
                  // dataRequest={dataRequest}
                />
              </TabPanel>
              <TabPanel style={tabPanelStyle}>
                <ProfessionalQualification
                  user={dataRequest.user}
                  // dataRequest={dataRequest}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <HStack justifyContent='space-between'>
          <NakedButton
            textColor='#E0180B'
            label='Decline'
            onClick={() => {
              setViewRequestModal(false)
              setShowDeclineModal(true)
            }}
          />
          <SolidButton
            height='40px'
            backgroundColor='rgba(0, 151, 61, 0.9)'
            hoverBackgroundColor='rgba(0, 151, 61, 0.8)'
            label='Approve'
            onClick={() =>
              handleApproval(
                dataRequest,
                `${dataRequest.user.first_name} ${dataRequest.user.first_name}`
              )
            }
          />
        </HStack>
      </Modal>
    </>
  )
}

export interface AllDataRequestsListProps {
  dataRequests: DataRequest[]
  fetchAllRequests: () => void
  dataRequestOverview: () => void
}

const AllDataRequestsList: React.FC<AllDataRequestsListProps> = ({
  dataRequests,
  fetchAllRequests,
  dataRequestOverview,
}) => {
  return (
    <Grid
      templateColumns='repeat(auto-fit, minmax(420px, 1fr))'
      gap={8}
      marginBottom='56px'
    >
      {dataRequests.map(dataRequest => (
        <RequestCard
          key={dataRequest.id}
          fetchAllRequests={fetchAllRequests}
          dataRequestOverview={dataRequestOverview}
          dataRequest={dataRequest}
        />
      ))}
    </Grid>
  )
}

export default AllDataRequestsList