import { HStack, Text, Circle, Link } from "@chakra-ui/layout";
import { NavLink } from "react-router-dom";


export interface PaginationProps {
  totalResults: number;
  currentPage: number;
  totalPages: number;
  link?: string;
}

const getColor = (isCurrentPage: boolean): string => {
  return isCurrentPage ? "rgba(14, 98, 255, 1)" : "rgba(23, 43, 77, 0.6)";
}

const circleStyle = {
  minWidth: "30px",
  minHeight: "30px",
  background: "rgba(42, 129, 255, 0.04)",
}

const getBorderStyle = (hasBorder: boolean) => {
  return hasBorder ? "1px solid rgba(14, 98, 255, 1)" : "none"
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  totalResults,
  link,
}) => {
  const currentCountNumber = currentPage <= 1 ? 1 : ((currentPage - 1) * 10) + 1;
  const currentCountTotal = currentPage === totalPages? totalResults : (currentCountNumber - 1) + 10;
  
  if(totalResults === totalPages){
    return <></>;
  }

  const centerLeftButtons = (
    pageNumber: number,
    last: number,
    link: string
  ) => {
    let centerLeft = []
    let linkFormat = ""
    link.includes("?") ? (linkFormat = "&page=") : (linkFormat = "?page=")
    if (last > 1) {
      if (pageNumber - 2 < 0) {
        return <></>
      }
      for (let i = pageNumber - 2; i < pageNumber; i++) {
        if (i < 1) continue
        centerLeft.push(
          <Circle key={i * 2} {...circleStyle} border={getBorderStyle(false)}>
            <Link as={NavLink} to={link ? link + linkFormat + i : "#"}>
              <Text
                as='span'
                color={getColor(true)}
                fontWeight='500'
                fontSize={14}
              >
                {i}
              </Text>
            </Link>
          </Circle>
        )
      }
      return centerLeft
    }
  }

  const centerButton = (pageNumber: number, last: number, link: string) => {
    if (last <= 1) {
      return <></>
    }
    let linkFormat = ""
    link.includes("?") ? (linkFormat = "&page=") : (linkFormat = "?page=")
    return (
      <Circle {...circleStyle} border={getBorderStyle(true)}>
        <Link as={NavLink} to={link ? link + linkFormat + pageNumber : "#"}>
          <Text as='span' color={getColor(true)} fontWeight='500' fontSize={14}>
            {pageNumber}
          </Text>
        </Link>
      </Circle>
    )
  }

  const centerRightButtons = (
    pageNumber: number,
    last: number,
    link: string
  ) => {
    let centerRight = []
    let linkFormat = ""
    link.includes("?") ? (linkFormat = "&page=") : (linkFormat = "?page=")
    if (last > 1) {
      const nextCount = pageNumber + 2 > last ? last : pageNumber + 2
      for (let i = pageNumber + 1; i <= nextCount; i++) {
        centerRight.push(
          <Circle key={i} {...circleStyle} border={getBorderStyle(false)}>
            <Link as={NavLink} to={link ? link + linkFormat + i : "#"}>
              <Text
                as='span'
                color={getColor(true)}
                fontWeight='500'
                fontSize={14}
              >
                {i}
              </Text>
            </Link>
          </Circle>
        )
        if (i > pageNumber + 4) break
      }
      return centerRight
    }
  }

  if(totalPages <= 1){
    return (<></>);
  }

  return (
    <HStack justifyContent="space-between">
      <Text color="rgba(23, 43, 77, 0.6)" fontWeight="500" fontSize={14}>
        Showing {currentCountNumber}-{currentCountTotal} of {totalResults} results
      </Text>
      {/* pages */}
      <HStack spacing={3}>
        {centerLeftButtons(currentPage, totalPages, link || '')}
        {centerButton(currentPage, totalPages, link || '')}
        {centerRightButtons(currentPage, totalPages, link || '')}
      </HStack>
    </HStack>
  );
};

export default Pagination;
