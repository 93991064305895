import React, { useEffect } from "react"
import UserProfileContent from "../components/UserProfileContent"
import { useParams } from 'react-router-dom';
import HttpClient from '../helpers/HttpClient';
import { AxiosResponse } from "axios";
import { ResponseHelper } from "../helpers/interfaces/ResponseHelper";
import { User } from "../helpers/interfaces/User";

export interface MemberProfilePageProps {

}

export interface MemberProfilePagePrams {
  id?: string
}

const defaultUser: User = {
  id: 0,
  username: "----",
  first_name: "----",
  last_name: "----",
  phone: "----",
  country: "----",
  city: "----",
  home_address: "----",
  join_reason: "----",
  work_file: "----",
  referee_1_name: "----",
  referee_1_email: "----",
  referee_1_relationship: "----",
  referee_2_name: "----",
  referee_2_email: "----",
  referee_2_relationship: "----",
  resume_file: "----",
  current_occupation: "----",
  organization_name: "----",
  personal_email: "----",
  professional_email: "----",
  expertise: "----",
  avatar: "----",
  role: "----",
  created_at: "----",
  updated_at: "----",
  linkedin_profile: "----",
  login_email: "----",
  hear_about_us: "----",
  approved_status: "----",
} as User;

const MemberProfilePage: React.FC<MemberProfilePageProps> = () => {
  const [approvedUser, setApprovedUser] = React.useState<User>(defaultUser)
  const { id } = useParams<MemberProfilePagePrams>()
  // console.log(id);

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await HttpClient.get<
          AxiosResponse<ResponseHelper<User>>
        >(`admin/user/${id}`)
        // console.log('data', data)
        setApprovedUser(data.data)
      } catch (error) {
        console.log(error.response.data)
      }
    })()
  }, [id]) //remove id
  return (
    <>
      <UserProfileContent
        reactivate={false}
        sendMessage={true}
        deactivate={true}
        route='members'
        routeName='All Members'
        user={approvedUser}
      />
    </>
  )
}

export default MemberProfilePage;
