import { Box, Container, Flex, Image, Spacer } from "@chakra-ui/react";
import { PrimaryButton } from "./buttons";

export interface LoginLayoutProps {}

const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
    return (<Box
      width="100wh"
      height="100vh"
      bg="linear-gradient(91.19deg, rgba(227, 239, 255, 0.5) 24.61%, rgba(227, 239, 255, 0) 123.38%)"
    >
        <Box bg="white">
            <Container maxW="container.xl">
                <Flex alignItems="center">
                    <Image py="24px" src="icon_logo.svg" alt="ADEIN Logo" />
                    <Spacer />
                    <PrimaryButton height="40px" label="Go To Website" onClick={() => null} />
                </Flex>
            </Container>
        </Box>
        <Flex alignItems="center" position="absolute" w="100%" mt="40">
            <Container maxW="container.xl">{children}</Container>
        </Flex>
    </Box>
    );
}

export default LoginLayout;