import { Box, HStack, Avatar, VStack, Divider, Text } from '@chakra-ui/react';
import { GreenButton, NoBorderRedButton } from '../buttons';
import Modal from '../modal';
import { Column } from '../modalTabContent';


export interface ViewActivityModalProps {
    showModal: boolean;
    handleClose: () => void;
}

 const ViewActivityModal: React.FC<ViewActivityModalProps>  = ({ showModal, handleClose}) => {
    return (
        <Modal
        width="639px"
        isOpen={showModal}
        close={handleClose}
        title="Data Request"
        closableOutside={true}
      >
        <Box marginY={5}>
          <HStack marginY={8}>
            <Avatar
              color="rgba(23, 43, 77, 0.5)"
              background="rgba(23, 43, 77, 0.1)"
              size="md"
              name="Chidinma Aniugbo"
            />
            <VStack alignItems="flex-start" fontSize={15} spacing={0}>
              <Text color="rgba(23, 43, 77, 0.9)" fontWeight="600">
                Chidinma Aniugbo
              </Text>
              <Text color="rgba(23, 43, 77, 0.7)" fontWeight="500">
                Chidinmaaniugbo@gmail.com
              </Text>
            </VStack>
          </HStack>
          <Divider />
          <VStack alignItems="flex-start" spacing={8} width="100%">
            <HStack justifyContent="space-between" width="100%">
              <Column title="Data Requested" value="Malaria" align="left" />
              <Column
                title="Date Requested"
                value="01 October, 2020"
                align="right"
              />
            </HStack>
            <HStack justifyContent="space-between" width="100%">
              <Column
                title="Reason For Data Request"
                value="When you register for one of our online events, you'll receive a reminder."
                align="left"
              />
            </HStack>
          </VStack>
        </Box>
        <HStack justifyContent="space-between">
          <NoBorderRedButton
            textColor="#E0180B"
            label="Decline"
            onClick={() => alert(false)}
          />
          <GreenButton
            height="40px"
            label="Approve"
            onClick={() => alert()}
          />
        </HStack>
      </Modal>
      )
}

export default ViewActivityModal;