import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { SearchInput } from "../../components/inputs";
// import Filter from "../../components/filter"
import ApprovedDataRequestsList from "../../components/lists/databank/approvedDataRequests";
import { AxiosResponse } from "axios";
import { ResponseHelper } from "../../helpers/interfaces/ResponseHelper";
import HttpClient from "../../helpers/HttpClient";
import { DataRequest } from "../../helpers/interfaces/DataRequest";
import { useLocation } from "react-router";
import Pagination from "../../components/Pagination";
import { useDebouncedEffect } from "../../effects/useDebouncedEffect";

export interface ApprovedDataRequestsProps {}

const myPath = "/databank?tab=Approved Data Requests";

const ApprovedDataRequests: React.FC<ApprovedDataRequestsProps> = () => {
  const location = useLocation();

  const [searchQuery, setsearchQuery] = React.useState("");
  const [pagination, setPagination] = React.useState<any>();
  const [approvedDataRequests, setApprovedDataRequests] = React.useState<
    DataRequest[]
  >([] as DataRequest[]);

  const fetchApprovedrequests = async () => {
    const queryString = location.search;
    if (queryString) {
      const { data } = await HttpClient.get<
        AxiosResponse<ResponseHelper<DataRequest[]>>
      >(`admin/data-request/approved${queryString}`);

      setPagination({
        page: data.page,
        total: data.total,
        totalPages: data.lastPage,
      });
      setApprovedDataRequests(data.data);
    }
  };

  useDebouncedEffect(
    () => {
      (async () => {
        if (searchQuery.length) {
          const searchParams = new URLSearchParams(location.search);
          searchParams.set("query", searchQuery);
          // let query = searchParams.toString();
          let query = searchParams.get("query");

          const { data } = await HttpClient.get<
            AxiosResponse<ResponseHelper<DataRequest[]>>
          >(`admin/data-request/search?status=APPROVED&query=${query}`);
          setPagination({
            page: data.page,
            total: data.total,
            totalPages: data.lastPage,
          });
          setApprovedDataRequests(data.data);
        } else {
          const queryString = location.search;
          const { data } = await HttpClient.get<
            AxiosResponse<ResponseHelper<DataRequest[]>>
          >("admin/data-request/approved" + queryString);
          setPagination({
            page: data.page,
            total: data.total,
            totalPages: data.lastPage,
          });
          setApprovedDataRequests(data.data);
        }
      })();
    },
    [searchQuery],
    1000
  );

  React.useEffect(() => {
    fetchApprovedrequests();
  }, [location]);

  return (
    <Box paddingX={16}>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        marginTop='30px'
        marginBottom='21px'
      >
        <Text color='brand.dark' fontSize={18} fontWeight='500'>
          Approved Data Requests
        </Text>
        <SearchInput
          placeholder='Search for a request'
          onChange={payload => setsearchQuery(payload)}
        />
        {/* <Filter /> */}
      </Flex>

      {/* members */}
      <ApprovedDataRequestsList approvedDataRequests={approvedDataRequests} />

      <Box width='100%' background='white' paddingX={5} paddingY={5}>
        <Pagination
          totalResults={pagination?.total}
          currentPage={pagination?.page}
          totalPages={pagination?.totalPages}
          link={myPath}
        />
      </Box>
    </Box>
  );
};

export default ApprovedDataRequests;
