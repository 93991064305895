import { HStack, Box, Text, Link } from '@chakra-ui/react';
import * as React from 'react';

export interface RowProps {
    title: string
    value: string
    link?: true
  }

const Row: React.FC<RowProps> = ({ title, value, link, }) => {
    return (
      <HStack
        fontWeight="500"
        fontSize={14}
        justifyContent="space-between"
        width="100%"
      >
        <Text width="50%" color="rgba(52, 69, 99, 0.6)">
          {title}
        </Text>
        <Box width="50%">
          {link ? (
            <Link href={value} color="#2A81FF" textDecoration="underline">
              {value}
            </Link>
          ) : (
            <Text color="rgba(9, 30, 66, 0.9)">{value}</Text>
          )}
        </Box>
      </HStack>
    )
  }

  export default Row;