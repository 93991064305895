import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Text,
  Tr,
  Th,
  Td,
  HStack,
  Avatar,
} from "@chakra-ui/react"
import { PrimaryOutlineButton } from '../buttons';
import { useHistory } from "react-router-dom"
import { User } from '../../helpers/interfaces/User';

const environ = process.env.NODE_ENV
export interface MembersListProps {
  users: User[];
}

const MembersList: React.FC<MembersListProps> = ({ users }) => {
  const history = useHistory()
  const [appUrl, setAppUrl] = React.useState("")

  React.useEffect(() => {
    environ === "production"
      ? setAppUrl("https://api.adein.cchub.org")
      : setAppUrl("https://api.adein.cchub.xyz")
  }, [])

  return (
    <>
      <Table
        variant='striped'
        colorScheme='gray'
        background='white'
        border='1px solid rgba(103, 121, 142, 0.1)'
        rounded={5}
      >
        <Thead>
          <Tr>
            <Th paddingY={5} color='rgba(8, 35, 91, 0.6)'>
              FULL NAME
            </Th>
            <Th paddingY={5} color='rgba(8, 35, 91, 0.6)'>
              EMAIL ADDRESS
            </Th>
            <Th paddingY={5} color='rgba(8, 35, 91, 0.6)'>
              OCCUPATION
            </Th>
            <Th paddingY={5} color='rgba(8, 35, 91, 0.6)'>
              COUNTRY
            </Th>
            <Th paddingY={5} color='rgba(8, 35, 91, 0.6)'></Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user, index) => (
            <Tr key={index}>
              <Td>
                <HStack>
                  <Avatar
                    size='sm'
                    name={`${user.first_name} ${user.last_name}`}
                    color='rgba(23, 43, 77, 0.5)'
                    background='rgba(23, 43, 77, 0.1)'
                    src={`${appUrl}/${user?.avatar}`}
                  />
                  <Text
                    fontSize={14}
                    fontWeight='500'
                    color='rgba(23, 43, 77, 0.9)'
                  >
                    {user.first_name} {user.last_name}
                  </Text>
                </HStack>
              </Td>
              <Td>
                <Text
                  fontSize={14}
                  fontWeight='500'
                  color='rgba(23, 43, 77, 0.9)'
                >
                  {user.login_email}
                </Text>
              </Td>
              <Td>
                <Text
                  fontSize={14}
                  fontWeight='500'
                  color='rgba(23, 43, 77, 0.9)'
                >
                  {user.current_occupation}
                </Text>
              </Td>
              <Td>
                <Text
                  fontSize={14}
                  fontWeight='500'
                  color='rgba(23, 43, 77, 0.9)'
                >
                  {user.country}
                </Text>
              </Td>
              <Td isNumeric>
                <PrimaryOutlineButton
                  label='View'
                  onClick={() =>
                    history.push(`/members/${user.id}?tab=Personal Information`)
                  }
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  )
}

export default MembersList
