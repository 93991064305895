import React from "react";
import { useLocation } from "react-router";
import { Box, HStack, Text } from "@chakra-ui/react";
import StatCard from "../../components/cards/statCard";
import { SearchInput } from "../../components/inputs";
import Pagination from "../../components/Pagination";
import { ResponseHelper } from "../../helpers/interfaces/ResponseHelper";
import { useDebouncedEffect } from "../../effects/useDebouncedEffect";
import HttpClient from "../../helpers/HttpClient";
import { AxiosResponse } from "axios";
import AllDataRequestsList from "../../components/lists/databank/allRequests";
import { DataRequest } from "../../helpers/interfaces/DataRequest";
import { OverviewSection } from "../../helpers/interfaces/OverviewSection";
export interface AllDataRequestsProps {}

const myPath = "/databank?tab=All Data Requests";

const defaultStats: OverviewSection[] = [
  {
    name: "Total Pending Data Requests",
    status: "---",
    amount: "---",
    percentage: "--",
  },
  {
    name: "Approved Data Requests",
    status: "---",
    amount: "---",
    percentage: "--",
  },
  {
    name: "Declined Data Requests",
    status: "---",
    amount: "---",
    percentage: "--",
  },
];

const AllDataRequests: React.FC<AllDataRequestsProps> = () => {
  const location = useLocation();

  const [searchQuery, setSearchQuery] = React.useState("");
  const [pagination, setPagination] = React.useState<any>();
  const [allDataRequests, setAllDataRequests] = React.useState<DataRequest[]>(
    [] as DataRequest[]
  );

  const [overview, setOverview] =
    React.useState<OverviewSection[]>(defaultStats);

  const fetchAllrequests = async () => {
    const { data } = await HttpClient.get<
      AxiosResponse<ResponseHelper<DataRequest[]>>
    >(`admin/data-request`);
    setPagination({
      page: data.page,
      total: data.total,
      totalPages: data.lastPage,
    });
    setAllDataRequests(data.data);
  };

  const dataRequestOverview = async () => {
    const { data } = await HttpClient.get<
      AxiosResponse<ResponseHelper<OverviewSection[]>>
    >(`admin/data-request/overview`);
    const filterOverview = data?.data?.filter(
      x => x.name !== "Total Data Request"
    );
    setOverview(filterOverview);
  };

  useDebouncedEffect(
    () => {
      (async () => {
        if (searchQuery.length) {
          const searchParams = new URLSearchParams(location.search);
          searchParams.set("query", searchQuery);
          // let query = searchParams.toString();
          let query = searchParams.get("query");

          const { data } = await HttpClient.get<
            AxiosResponse<ResponseHelper<DataRequest[]>>
          >(`admin/data-request/search?query=${query}`);
          setPagination({
            page: data.page,
            total: data.total,
            totalPages: data.lastPage,
          });
          setAllDataRequests(data.data);
        } else {
          const queryString = location.search;
          const { data } = await HttpClient.get<
            AxiosResponse<ResponseHelper<DataRequest[]>>
          >("admin/data-request" + queryString);
          setPagination({
            page: data.page,
            total: data.total,
            totalPages: data.lastPage,
          });
          setAllDataRequests(data.data);
        }
      })();
    },
    [searchQuery],
    1000
  );

  React.useEffect(() => {
    dataRequestOverview();
    fetchAllrequests();
  }, []);

  return (
    <Box paddingX={16}>
      {/* stats */}
      <HStack justifyContent='space-between'>
        {overview.map(stat => (
          <StatCard key={stat.name} stat={stat} />
        ))}
      </HStack>

      <HStack
        justifyContent='space-between'
        marginTop='72px'
        marginBottom='36px'
      >
        <Text color='brand.dark' fontSize={18} fontWeight='500'>
          All Data Requests
        </Text>
        <SearchInput
          placeholder='Search for a request'
          onChange={payload => setSearchQuery(payload)}
        />
      </HStack>

      <AllDataRequestsList
        fetchAllRequests={fetchAllrequests}
        dataRequests={allDataRequests}
        dataRequestOverview={dataRequestOverview}
      />

      <Pagination
        totalResults={pagination?.total}
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        link={myPath}
      />
    </Box>
  );
};

export default AllDataRequests;
