import React from "react"
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Fonts from "./Fonts"

// routes
import LoginPage from "./pages/LoginPage"
import RequestPage from "./pages/RequestPage"
import AllMembersPage from "./pages/AllMembersPage"
import MemberProfilePage from "./pages/MemberProfilePage"
import DeactivatedMemberProfile from "./pages/DeactivatedMemberProfilePageProps"
import DeactivatedMembersPage from "./pages/DeactivatedMembersPage"
import DataBank from "./pages/Databank"
import NotFound from "./pages/404"
import ResetPasswordPage from './pages/ResetPasswordPage';

// layout
import { ProtectedRoute } from "./pages/_layouts/dashboardLayout"
import SetPasswordPage from "./pages/SetPasswordPage"

const colors = {
  brand: {
    light: "#0E62FF",
    primary: "#0A2E65",
  },
}

const theme = extendTheme({
  fonts: {
    heading: "Campton",
    body: "Campton",
  },
  colors,
})

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Router>
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/resetPassword" component={ResetPasswordPage} />
          <Route exact path="/setPassword" component={SetPasswordPage} />
          <ProtectedRoute exact path="/request" component={RequestPage} />
          <ProtectedRoute exact path="/members" component={AllMembersPage} />
          <ProtectedRoute
            exact
            path="/members/:id"
            component={MemberProfilePage}
          />
          <ProtectedRoute
            exact
            path="/deactivated-members"
            component={DeactivatedMembersPage}
          />
          <ProtectedRoute
            exact
            path="/deactivated-members/:id"
            component={DeactivatedMemberProfile}
          />
          <ProtectedRoute exact path="/databank" component={DataBank} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </ChakraProvider>
  )
}

export default App
