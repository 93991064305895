import React from "react"
import { FormControl, FormLabel, Select, Textarea } from "@chakra-ui/react"

export const CustomDropDown = ({
  placeholder,
  handleOption,
  value,
  options,
  label,
  width,
  required,
}) => {
  return (
    <FormControl
      isRequired={required}
      width={width}
      borderColor='#ddd'
      borderStyle='solid'
      borderWidth={1}
      borderRadius={4}
      paddingY={2}
      paddingX={4}
      background='#fff'
    >
      <FormLabel
        htmlFor='email'
        color='#616164'
        fontWeight={400}
        fontSize={15}
        margin={0}
      >
        {label}
      </FormLabel>
      <Select
        placeholder={placeholder}
        border='none'
        background='transparent'
        _hover=''
        _focus=''
        fontWeight={500}
        // fontSize={18}
        padding={0}
        value={value}
        onChange={e => handleOption(e.target.value)}
      >
        {options.map((option, index) => {
          return (
            <option key={index} value={option.name}>
              {option.name}
            </option>
          )
        })}
      </Select>
    </FormControl>
  )
}

export const CustomTextarea = ({
  placeholder,
  inputType,
  handleInput,
  value,
  label,
  width,
  required,
}) => {
  const [isFocused, setisFocused] = React.useState(false)

  const handleFocus = () => setisFocused(true)
  const handleBlur = () => setisFocused(false)
  return (
    <FormControl
      isRequired={required}
      width={width}
      borderColor='#ddd'
      borderStyle='solid'
      borderWidth={1.5}
      borderRadius={4}
      paddingY={2}
      paddingX={4}
      background='#fff'
      style={{ borderColor: isFocused ? "#2A81FF" : "#ddd" }}
    >
      <FormLabel
        htmlFor='email'
        color='#616164'
        fontWeight={400}
        fontSize={15}
        margin={0}
      >
        {label}
      </FormLabel>
      <Textarea
        onBlur={handleBlur}
        onFocus={handleFocus}
        value={value}
        onChange={e => handleInput(e.target.value)}
        _placeholder={{ color: "#ddd", fontWeight: 400 }}
        fontWeight={400}
        fontSize={14}
        variant='unstyled'
        placeholder={placeholder}
        border='none'
        type={inputType}
      />
    </FormControl>
  )
}
