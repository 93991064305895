import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { Search2Icon } from "@chakra-ui/icons";
import { Button, Text, Box, Image } from "@chakra-ui/react";
import { useState } from "react";


export interface SearchInputProps {
  placeholder: string;
  onChange: (text: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  onChange,
}: SearchInputProps) => {
  return (
    <>
      <InputGroup background="white" width="300px">
        <InputLeftElement
          pointerEvents="none"
          children={<Search2Icon color="gray.300" />}
        />
        <Input
          type="tel"
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          _focus={{ borderWidth: "1px", borderColor: "#0E62FF" }}
          borderColor="rgba(103, 121, 142, 0.15)"
          fontWeight="300"
        />
      </InputGroup>
    </>
  );
};



export interface CombineInputProps {
  isPassword?: boolean;
  value?: string;
  label: string;
  placeholder?: string;
  onChange: (e: any) => void;
}

export const CombineInput: React.FC<CombineInputProps> = ({
  value = '',
  isPassword = false,
  placeholder = "",
  label,
  onChange
}) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <Box position="relative">
      {isPassword ? (
        <>
          <Button
            h="1.75rem"
            w="auto"
            mt="26px"
            mr="20px"
            p="0"
            variant="link"
            zIndex={2000}
            right="0px"
            size="sm"
            position="absolute"
            onClick={() => setShowPassword(!showPassword)}>
            <Image src="icon_eye.svg" />
          </Button>
          <Input
            pt="30px"
            pl="20px"
            height="69px"
            errorBorderColor="red.300"
            bg="rgba(237, 243, 251, 0.2)"
            onChange={(e) => onChange(e)}
            type={showPassword ? "text" : "password"}
            placeholder={placeholder} />
        </>
      ) : (
        <Input
          pt="30px"
          value={value}
          pl="20px"
          height="69px"
          errorBorderColor="red.300"
          bg="rgba(237, 243, 251, 0.2)"
          onChange={(e) => onChange(e)}
          type="text"
          placeholder={placeholder} />
      )}

      <Text
        as="label"
        pl="20px"
        left="0px"
        color="#172b4d80"
        style={{ fontWeight: 300 }}
        mt="8px !important"
        position="absolute">{label}</Text>

    </Box>
  )
}
