import React from "react"
import { User } from '../helpers/interfaces/User';
import UserProfileContent from '../components/UserProfileContent';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HttpClient from '../helpers/HttpClient';
import { ResponseHelper } from '../helpers/interfaces/ResponseHelper';
import { AxiosResponse } from 'axios';
import { MemberProfilePagePrams } from './MemberProfilePage';

export interface DeactivatedMemberProfilePageProps {
  user: User;
}

const DeactivatedMemberProfilePage: React.FC<DeactivatedMemberProfilePageProps> = ({ user }) => {
  const [approvedUser, setApprovedUser] = React.useState<User>();
  const { id } = useParams<MemberProfilePagePrams>();

  useEffect(() => {
    (async () => {
      const { data } = await HttpClient.get<AxiosResponse<ResponseHelper<User>>>(`admin/user/${id}`);
      setApprovedUser(data.data);
    })();
  }, [id]);

  return (
    <>
      <UserProfileContent
        reactivate={true}
        sendMessage={false}
        deactivate={false}
        route="deactivated-members"
        routeName="Deactivated Members"
        user={approvedUser}
      />
    </>
  )
}

export default DeactivatedMemberProfilePage
