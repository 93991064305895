import {
  RequestIcon,
  AllMembersIcon,
  DeactivatedIcon,
  DatabankIcon,
  // SharedlearningIcon,
  // InnovationIcon,
  // ResourcedIcon,
  // MessagesIcon,
  // AdminIcon,
  // SettingsIcon,
} from "../sidebar-icons"
const menus = [
  {
    title: "Manage Members",
    paths: [
      { name: "Request", link: "/request", icon: <RequestIcon /> },
      { name: "All Members", link: "/members", icon: <AllMembersIcon /> },
      {
        name: "Deactivated",
        link: "/deactivated-members",
        icon: <DeactivatedIcon />,
      },
    ],
  },
  {
    title: "Publications",
    paths: [
      {
        name: "Databank",
        link: "/databank?tab=All Data Requests",
        icon: <DatabankIcon />,
      },
      // {
      //   name: "Shared Learning",
      //   link: "/shared-learning",
      //   icon: <SharedlearningIcon />,
      // },
      // { name: "Innovation", link: "/innovation", icon: <InnovationIcon /> },
      // { name: "Resources", link: "/resources", icon: <ResourcedIcon /> },
    ],
  },
  // {
  //   title: "Others",
  //   paths: [
  //     { name: "Messages", link: "/messages", icon: <MessagesIcon /> },
  //     { name: "Admin", link: "/admin", icon: <AdminIcon /> },
  //     { name: "Settings", link: "/settings", icon: <SettingsIcon /> },
  //   ],
  // },
]
export default menus
