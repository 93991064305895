import React from "react"
import {
  Box,
  Table,
  Thead,
  Tbody,
  Text,
  Tr,
  Th,
  Td,
  HStack,
  VStack,
  Avatar,
  Divider,
} from "@chakra-ui/react"
import moment from "moment";
import { BorderButton } from "../../buttons";
import Modal from "../../modal";
import { Column } from "../../modalTabContent";
import { DataRequest } from "../../../helpers/interfaces/DataRequest";
export interface MembersListProps {
  approvedDataRequests: DataRequest[];
}

const environ = process.env.NODE_ENV;

const ApprovedDataRequestsList: React.FC<MembersListProps> = ({
  approvedDataRequests,
}) => {
  // modals
  const [showRequestModal, setShowRequestModal] = React.useState(false);
  const [appUrl, setAppUrl] = React.useState("");
  const [requestData, setRequestData] = React.useState<DataRequest>(
    {} as DataRequest
  );

  const handleModalShow = (data: DataRequest) => {
    setRequestData(data);
    setShowRequestModal(true);
  };

  React.useEffect(() => {
    environ === "production"
      ? setAppUrl("https://api.adein.cchub.org")
      : setAppUrl("https://api.adein.cchub.xyz");
  }, []);

  return (
    <>
      <Table
        variant='striped'
        colorScheme='gray'
        background='white'
        border='1px solid rgba(103, 121, 142, 0.1)'
        rounded={5}
      >
        <Thead>
          <Tr>
            <Th paddingY={5} color='rgba(8, 35, 91, 0.6)'>
              FULL NAME
            </Th>
            <Th paddingY={5} color='rgba(8, 35, 91, 0.6)'>
              EMAIL ADDRESS
            </Th>
            <Th paddingY={5} color='rgba(8, 35, 91, 0.6)'>
              DATE REQUESTED
            </Th>
            <Th paddingY={5} color='rgba(8, 35, 91, 0.6)'>
              DATA REQUESTED
            </Th>
            <Th paddingY={5} color='rgba(8, 35, 91, 0.6)'></Th>
          </Tr>
        </Thead>
        <Tbody>
          {approvedDataRequests.map(approvedDataRequest => (
            <Tr key={approvedDataRequest.id}>
              <Td>
                <HStack>
                  <Avatar
                    size='sm'
                    name={`${approvedDataRequest?.user?.first_name} ${approvedDataRequest?.user?.last_name}`}
                    color='rgba(23, 43, 77, 0.5)'
                    background='rgba(23, 43, 77, 0.1)'
                    src={`${appUrl}/${approvedDataRequest?.user?.avatar}`}
                  />
                  <Text
                    fontSize={14}
                    fontWeight='500'
                    color='rgba(23, 43, 77, 0.9)'
                  >
                    {`${approvedDataRequest?.user?.first_name} ${approvedDataRequest?.user?.last_name}`}
                  </Text>
                </HStack>
              </Td>
              <Td>
                <Text
                  fontSize={14}
                  fontWeight='500'
                  color='rgba(23, 43, 77, 0.9)'
                >
                  {approvedDataRequest?.user?.login_email}
                </Text>
              </Td>
              <Td>
                <Text
                  fontSize={14}
                  fontWeight='500'
                  color='rgba(23, 43, 77, 0.9)'
                >
                  {moment(approvedDataRequest.created_at).format(
                    "DD MMMM, YYYY"
                  )}
                </Text>
              </Td>
              <Td>
                <Text
                  fontSize={14}
                  fontWeight='500'
                  color='rgba(23, 43, 77, 0.9)'
                >
                  {approvedDataRequest.data}
                </Text>
              </Td>
              <Td isNumeric>
                <BorderButton
                  borderColor='#0E62FF'
                  height='30px'
                  backgroundColor='transparent'
                  textColor='#0E62FF'
                  label='View'
                  onClick={() => handleModalShow(approvedDataRequest)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* VIEW REQUEST  */}
      <Modal
        width='639px'
        isOpen={showRequestModal}
        close={() => setShowRequestModal(false)}
        title='Data Request'
        closableOutside={false}
      >
        <Box marginY={5}>
          <HStack marginY={8}>
            <Avatar
              color='rgba(23, 43, 77, 0.5)'
              background='rgba(23, 43, 77, 0.1)'
              size='md'
              name={`${requestData?.user?.first_name} ${requestData?.user?.last_name}`}
              src={`${appUrl}/${requestData?.user?.avatar}`}
            />
            <VStack alignItems='flex-start' fontSize={15} spacing={0}>
              <Text color='rgba(23, 43, 77, 0.9)' fontWeight='600'>
                {`${requestData?.user?.first_name} ${requestData?.user?.last_name}`}
              </Text>
              <Text color='rgba(23, 43, 77, 0.7)' fontWeight='500'>
                {requestData?.user?.login_email}
              </Text>
            </VStack>
          </HStack>
          <Divider />
          <VStack
            alignItems='flex-start'
            spacing={8}
            width='100%'
            marginTop='30px'
          >
            <HStack justifyContent='space-between' width='100%'>
              <Column
                title='Data Requested'
                value={requestData?.data}
                align='left'
              />
              <Column
                title='Date Requested'
                value={moment(requestData?.created_at).format("DD MMMM, YYYY")}
                align='right'
              />
            </HStack>
            <HStack justifyContent='space-between' width='100%'>
              <Column
                title='Reason For Data Request'
                value={requestData?.reason}
                align='left'
              />
              <Column
                title='Date Approved'
                value={moment(requestData?.updated_at).format("DD MMMM, YYYY")}
                align='right'
              />
            </HStack>
          </VStack>
        </Box>
      </Modal>
    </>
  );
};

export default ApprovedDataRequestsList
