import * as React from "react";
import {
  HStack,
  GridItem,
  Text,
} from "@chakra-ui/react";
import { NoBorderButton, NoBorderRedButton, GreenButton } from './buttons';
import { MessageIcon, SuitcaseIcon } from "./icons";
import { User } from '../helpers/interfaces/User';


export interface RequestCardProps {
    user: User;
    removeFromList: Function;
    setCurrentUser: Function;
    showViewRequestModal: Function;
    showDeclineModal: Function;
    showApproveModal: Function;
  }
  
  const RequestCard: React.FC<RequestCardProps> = (
    { user, removeFromList, setCurrentUser, showViewRequestModal, showDeclineModal, showApproveModal }) => {
  
    return (
      <>
        <GridItem
          background="white"
          rounded={10}
          padding="30px"
          colSpan={1}
          border="1px solid rgba(103, 121, 142, 0.1)"
        >
          <Text
            color="rgba(23, 43, 77, 0.9)"
            fontSize={14}
            fontWeight="600"
            marginBottom={2}
          >
            {user.first_name} {user.last_name}
          </Text>
          <HStack
            spacing={10}
            color="rgba(23, 43, 77, 0.7)"
            fontSize={14}
            fontWeight="500"
            marginBottom={5}
          >
            <HStack>
              <MessageIcon /> <Text>{ user.login_email }</Text>
            </HStack>
  
            <HStack>
              <SuitcaseIcon /> <Text>{user.current_occupation || '----'}</Text>
            </HStack>
          </HStack>
  
          <Text color="rgba(23, 43, 77, 0.7)" fontSize={14} fontWeight="300">
            {user.join_reason}
          </Text>
  
          {/* actions */}
          <HStack justifyContent="space-between" marginTop={5}>
            <NoBorderButton
              textColor="#0E62FF"
              label="View"
              onClick={() => {setCurrentUser(user); showViewRequestModal(true);}}
            />
  
            <HStack spacing={8}>
              <NoBorderRedButton
                label="Decline"
                onClick={() => {setCurrentUser(user); showDeclineModal(true);}}
              />
              <GreenButton
                label="Approve"
                onClick={() =>  {setCurrentUser(user); showApproveModal(true);}}
              />
            </HStack>
          </HStack>
        </GridItem>
      </>
    );
  };

  export default RequestCard;