import {
  Table,
  Thead,
  Tbody,
  Text,
  Tr,
  Th,
  Td,
  HStack,
  Avatar,
  useToast,
} from "@chakra-ui/react"
import { PrimaryOutlineButton, GreenOutlineButton, GreenButton, NoBorderButton } from '../buttons';
import { useHistory } from "react-router-dom"
import { User } from '../../helpers/interfaces/User';
import HttpClient from '../../helpers/HttpClient';
import { useState } from 'react';
import { ConfirmModal } from "../modals/ConfirmModal";
import { Box } from '@chakra-ui/layout';

export interface DeactivatedMembersProps {
  users: User[];
  removeUserFromList: Function;
}

const DeactivatedMembersList: React.FC<DeactivatedMembersProps> = ({ users, removeUserFromList }) => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User>({} as User);
  const history = useHistory();
  const toast = useToast();

  const myToast = (description: string, isSuccessful: boolean = true) => {
    toast({
      description,
      status: isSuccessful ? "success" : "error",
      duration: 4000,
      isClosable: true,
      position: "top-right",
    });
  }

  const handleReactivation = async (user: User) => {
   // open Confirm Modal
   setCurrentUser(user);
   setShowConfirmModal(true);
  }

  const handleConfirmButton = async (user: User) => {
    try{
      await HttpClient.patch(`admin/users/approve/${user.id}`, null);
      myToast(`Successfully activated ${user.first_name} ${user.last_name}`);
      setShowConfirmModal(false);
      removeUserFromList(user);
    }catch(error: any){
      console.log(error?.response);
      myToast(error?.response?.data?.message, false);
    }
  }

  return (
    <>
      <Table
        variant="striped"
        colorScheme="gray"
        background="white"
        border="1px solid rgba(103, 121, 142, 0.1)"
        rounded={5}
      >
        <Thead>
          <Tr>
            <Th paddingY={5} color="rgba(8, 35, 91, 0.6)">
              FULL NAME
            </Th>
            <Th paddingY={5} color="rgba(8, 35, 91, 0.6)">
              EMAIL ADDRESS
            </Th>
            <Th paddingY={5} color="rgba(8, 35, 91, 0.6)">
              OCCUPATION
            </Th>
            <Th paddingY={5} color="rgba(8, 35, 91, 0.6)">
              COUNTRY
            </Th>
            <Th paddingY={5} color="rgba(8, 35, 91, 0.6)"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user) => (
            <Tr>
              <Td>
                <HStack>
                  <Avatar
                    size="sm"
                    name={`${user.first_name} ${user.last_name}`}
                    color="rgba(23, 43, 77, 0.5)"
                    background="rgba(23, 43, 77, 0.1)"
                  />
                  <Text
                    fontSize={14}
                    fontWeight="500"
                    color="rgba(23, 43, 77, 0.9)"
                  >
                    {user.first_name} {user.last_name}
                  </Text>
                </HStack>
              </Td>
              <Td>
                <Text
                  fontSize={14}
                  fontWeight="500"
                  color="rgba(23, 43, 77, 0.9)"
                >
                  {user.login_email}
                </Text>
              </Td>
              <Td>
                <Text
                  fontSize={14}
                  fontWeight="500"
                  color="rgba(23, 43, 77, 0.9)"
                >
                  {user.current_occupation}
                </Text>
              </Td>
              <Td>
                <Text
                  fontSize={14}
                  fontWeight="500"
                  color="rgba(23, 43, 77, 0.9)"
                >
                  {user.country}
                </Text>
              </Td>
              <Td isNumeric>
                <GreenOutlineButton
                  label="Reactivate"
                  onClick={() => handleReactivation(user)}
                />
              </Td>

              <Td isNumeric>
                <PrimaryOutlineButton
                  label="View"
                  onClick={() =>
                    history.push(
                      `/deactivated-members/${user.id}?tab=Personal Information`
                    )
                  }
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* confirm Modal */}
      <ConfirmModal title="Confirm Reactivation" showModal={showConfirmModal} handleClose={() => setShowConfirmModal(false)}>
        <Box marginY={5}>
          <Text fontSize={16} fontWeight="300">
            Are you sure you want deactivate
            <Text as="span" marginLeft={1} display="inline" fontWeight="500">
              {currentUser?.first_name} {currentUser?.last_name}?
            </Text>
          </Text>
        </Box>
        <HStack justifyContent="space-between">
          <NoBorderButton
            label="Cancel"
            onClick={() => setShowConfirmModal(false)}
          />
          <GreenButton
            label="Activate"
            onClick={() => handleConfirmButton(currentUser)}
          />
        </HStack>
      </ConfirmModal>
    </>
  )
}

export default DeactivatedMembersList;
