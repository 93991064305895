import { Box, Divider, HStack, VStack, Tag, Text } from "@chakra-ui/react"
import { ActivitiesIcon } from "./icons"
import { User } from '../helpers/interfaces/User';
import { useEffect, useState } from 'react';
import HttpClient from '../helpers/HttpClient';
import { AxiosResponse } from "axios";
import { ResponseHelper } from '../helpers/interfaces/ResponseHelper';

export interface ActivitiesProps {
  onClickView: () => void;
  user?: User;
}

const Activities: React.FC<ActivitiesProps> = ({ user, onClickView }) => {
  const [activities, setActivities] = useState<any[]>([]);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await HttpClient.get<AxiosResponse<ResponseHelper<any[]>>>(`/admin/users/${user?.id}/activities`);
        // console.log(data);
        setActivities(data.data);
      } catch (error) {
        console.log(error)
      }
    })();
  });

  return (
    <Box width="35%">
      <Box
        background="white"
        border="1px solid rgba(103, 121, 142, 0.1);"
        rounded="5px"
      >
        <Text
          paddingY="27px"
          paddingX="30px"
          fontWeight="500"
          fontSize={16}
          color="rgba(23, 43, 77, 0.8)"
        >
          Activities
        </Text>
        <Divider />
        {activities.length === 0 && <Text textAlign="center" py={10} color="#34456399">No Activities Found</Text>}
        {activities.map((item, index) => (
          <HStack key={index}
            justifyContent="space-between"
            alignItems="flex-start"
            padding="30px"
            background={
              index % 2 === 0 ? "rgba(31, 37, 51, 0.02)" : "white"
            }
          >
            <HStack spacing={4} alignItems="flex-start">
              <ActivitiesIcon />
              <VStack alignItems="flex-start">
                <Text fontSize={15}>
                  Requested to upload a publication
                </Text>
                <HStack fontSize={14} color="rgba(52, 69, 99, 0.6)">
                  <Text>01 October, 2020</Text>
                  <Text>10:00 AM</Text>
                </HStack>
                <Tag
                  size="sm"
                  variant="solid"
                  background="rgba(0, 151, 61, 0.2)"
                  color="#00973D"
                  fontWeight="500"
                  rounded="2px"
                >
                  Approved
                </Tag>
              </VStack>
            </HStack>
            <Box
              cursor="pointer"
              color="#0E62FF"
              fontWeight="300"
              onClick={onClickView}
            >
              View
            </Box>
          </HStack>
        ))}
      </Box>
    </Box>
  )
}

export default Activities