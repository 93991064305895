import { Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { RiseIcon, FallIcon } from "../icons";

export interface StatCardProps {
  stat: {
    name: string
    amount: string
    status: string
    percentage: string
  }
}

const StatCard: React.FC<StatCardProps> = ({ stat }: StatCardProps) => {
  return (
    <HStack
      background='white'
      border='1px solid rgba(103, 121, 142, 0.13)'
      rounded='10px'
      width='32%'
      padding='30px'
      justifyContent='space-between'
      alignItems='flex-start'
    >
      <VStack spacing={3} alignItems='flex-start'>
        <Text color='rgba(8, 35, 91, 0.8)' fontSize={16} fontWeight='300'>
          {stat.name}
        </Text>
        <Heading color='rgba(8, 35, 91, 0.85)' fontSize={24} fontWeight='700'>
          {stat.amount}
        </Heading>
      </VStack>
      <HStack>
        {stat.status === "rise" ? <RiseIcon /> : <FallIcon />}
        <Text
          fontSize={14}
          color={stat.status === "rise" ? "#3AA674" : "#F42C1F"}
          fontWeight='500'
        >
          {`${stat.percentage}%`}
        </Text>
      </HStack>
    </HStack>
  )
}

export default StatCard;
