import React from "react";
import { VStack, Image, Box, Text, HStack } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import logo from "../../logo.svg";
import { Polygon } from "../icons";
import menus from "./menus";
import "./styles.scss";

export interface SidebarProps {}

const Sidebar: React.FC<SidebarProps> = () => {
  return (
    <VStack
      height="100vh"
      background="brand.primary"
      position="sticky"
      left={0}
      top={0}
      minW="250px"
      alignItems="flex-start"
    >
      <Image marginTop={3} marginBottom={16} src={logo} paddingX="46px" />
      <VStack
        spacing={14}
        alignItems="flex-start"
        overflowY="auto"
        paddingLeft="46px"
        width="100%"
      >
        {menus.map((menu) => (
          <Box key={menu.title} width="100%">
            <Text
              color="rgba(249, 249, 249, 0.6)"
              fontSize={12}
              marginBottom={5}
              textTransform="uppercase"
            >
              {menu.title}
            </Text>
            <VStack
              width="100%"
              alignItems="flex-start"
              fontSize={16}
              spacing={4}
              fontWeight="300"
            >
              {menu.paths.map((path) => (
                <NavLink
                  key={path.name}
                  to={path.link}
                  className="menu-link"
                  activeClassName="menu-link-active"
                  style={{ width: "100%" }}
                >
                  <HStack justifyContent="space-between" width="100%">
                    <HStack>
                      <Box className="icon">{path.icon}</Box>
                      <Text>{path.name}</Text>
                    </HStack>
                    <Box className="polygon">
                      <Polygon />
                    </Box>
                  </HStack>
                </NavLink>
              ))}
            </VStack>
          </Box>
        ))}
      </VStack>
    </VStack>
  );
};

export default Sidebar;
