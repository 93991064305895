import {
  Box,
  Checkbox,
  Flex,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react"
import { FormEvent, useState } from "react"
import { useHistory } from "react-router-dom"
import HttpClient from "../helpers/HttpClient"
import { ResponseHelper } from "../helpers/interfaces/ResponseHelper"
import { LoginResponse } from "../helpers/interfaces/LoginRespnse"
import { AxiosResponse } from "axios"
import LoginLayout from "../components/LoginLayout"
import { WidePrimaryButton } from "../components/buttons"
import { CombineInput } from "../components/inputs"
const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const toast = useToast()
  const myToast = (description: string, isSuccessful: boolean) => {
    toast({
      description,
      status: isSuccessful ? "success" : "error",
      duration: 4000,
      isClosable: true,
      position: "top-right",
    })
  }
  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!email || !password) {
      myToast("Please fill the required fields", false)
      return
    }
    if (password.length < 8) {
      myToast("Password must be greater than 8 characters", false)
      return
    }
    // make Api Call
    setIsLoading(true)
    ;(async () => {
      try {
        const { data } = await HttpClient.post<
          AxiosResponse<ResponseHelper<LoginResponse>>
        >("admin/login", { login_email: email, password })
        if (data.data.user.role !== "SUPER_USER") {
          setIsLoading(false)
          myToast("Authorized personnel only", false)
        }
        // console.log('data.data.token', data.data.token);
        localStorage.setItem("token", data.data.token)
        history.push("/request")
      } catch (error: any) {
        setIsLoading(false)
        if (error?.response?.data?.data?.messages[0]?.message) {
          myToast(error?.response?.data?.data?.messages[0]?.message, false)
        } else if (error?.response?.data?.message) {
          myToast(error?.response?.data?.message.toLowerCase(), false)
        } else {
          myToast("Unknown Error!", false)
        }
      }
    })()
  }
  return (
    <LoginLayout>
      <Flex justifyContent='space-between' align='center'>
        <Box>
          <Text
            fontSize='7xl'
            color='brand.primary'
            fontWeight='900'
            style={{ lineHeight: 1.2 }}
          >
            Sign in to
            <br /> Adein Admin
            <br />
            Dashboard
          </Text>
        </Box>
        <Box
          bg='white'
          width='513px'
          py='50px'
          px='40px'
          border='1px'
          borderColor='#DEEAFA'
          borderRadius='10px'
        >
          <form onSubmit={submit}>
            <Stack spacing={4}>
              <CombineInput
                label='Email Address'
                value={email}
                placeholder='Enter Email Address'
                onChange={e => setEmail(e.target.value)}
              />
              <Link href='/resetPassword' align='right' color='brand.light'>
                Forgot password?
              </Link>
              <CombineInput
                label='Password'
                value={password}
                placeholder='Min 8 characters'
                onChange={e => setPassword(e.target.value)}
                isPassword
              />
              <Flex justifyContent='space-between'>
                <Checkbox>Keep me signed in</Checkbox>
                <WidePrimaryButton
                  label='Sign In'
                  height='50px'
                  onClick={() => {}}
                  isLoading={isLoading}
                  type='submit'
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      </Flex>
    </LoginLayout>
  )
}
export default LoginPage