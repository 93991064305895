import React from "react"
import { Box, Text, Flex } from "@chakra-ui/layout"
import { SearchInput } from "../components/inputs"
import Filter from "../components/filter"
import { useDebouncedEffect } from '../effects/useDebouncedEffect';
import HttpClient from "../helpers/HttpClient"
import { useLocation } from 'react-router-dom';
import { AxiosResponse } from "axios"
import { ResponseHelper } from '../helpers/interfaces/ResponseHelper';
import { User } from '../helpers/interfaces/User';
import MembersList from '../components/lists/MembersList';
import Pagination from "../components/Pagination";

export interface AllMembersPageProps {}

const AllMembersPage: React.FC<AllMembersPageProps> = () => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const location = useLocation();
  const [pagination, setPagination] = React.useState<any>();

  const [country, setCountry] = React.useState("");
  const [occupation, setOccupation] = React.useState("");

  const [approvedRequests, setApprovedRequests] = React.useState<User[]>(
    [] as User[]
  );

  useDebouncedEffect(
    () => {
      (async () => {
        if (searchQuery.length) {
          const searchParams = new URLSearchParams(location.search);
          searchParams.set("query", searchQuery);
          let query = searchParams.toString();
          const { data } = await HttpClient.get<
            AxiosResponse<ResponseHelper<User[]>>
          >(`admin/users/approved/search?${query}`);
          setPagination({
            page: data.page,
            total: data.total,
            totalPages: data.lastPage,
          });
          setApprovedRequests(data.data);
        } else {
          const queryString = location.search;
          const { data } = await HttpClient.get<
            AxiosResponse<ResponseHelper<User[]>>
          >("admin/users/approved" + queryString);
          setPagination({
            page: data.page,
            total: data.total,
            totalPages: data.lastPage,
          });
          setApprovedRequests(data.data);
        }
      })();
    },
    [searchQuery],
    1000
  );

  const fetchApprovedMembers = async () => {
    // const queryString = location.search;
    // if (queryString) {
    const { data } = await HttpClient.get<
      AxiosResponse<ResponseHelper<User[]>>
    >("admin/users/approved");
    setPagination({
      page: data.page,
      total: data.total,
      totalPages: data.lastPage,
    });
    setApprovedRequests(data.data);
    // }
  };

  React.useEffect(() => {
    fetchApprovedMembers();
  }, [location]);

  const filterUsers = async () => {
    const { data } = await HttpClient.get<
      AxiosResponse<ResponseHelper<User[]>>
    >(`admin/search/users?country=${country}&current_occupation=${occupation}`);
    setPagination({
      page: data.page,
      total: data.total,
      totalPages: data.lastPage,
    });
    setApprovedRequests(data.data);
  };

  const handleFilter = () => {
    filterUsers();
  };

  return (
    <Box marginTop='50px' paddingX={16}>
      <Text color='brand.primary' fontSize={18} fontWeight='500'>
        All Members
      </Text>

      <Flex
        justifyContent='space-between'
        alignItems='center'
        marginTop='30px'
        marginBottom='21px'
      >
        <SearchInput
          placeholder='Search for member'
          onChange={payload => setSearchQuery(payload)}
        />
        <Filter
          occupation={occupation}
          country={country}
          handleSelectOccupation={setOccupation}
          handleSelectCountry={setCountry}
          handleFilter={handleFilter}
          fetchApprovedMembers={fetchApprovedMembers}
        />
        {/* <Filter /> */}
      </Flex>

      {/* members */}
      <MembersList users={approvedRequests} />

      <Box mb={4} width='100%' paddingX={5} paddingY={5}>
        <Pagination
          totalResults={pagination?.total}
          currentPage={pagination?.page}
          totalPages={pagination?.totalPages}
          link={location.pathname}
        />
      </Box>
    </Box>
  );
};

export default AllMembersPage
