import { HStack, VStack, Text, Link } from "@chakra-ui/layout"
import moment from "moment"
import { DataRequest } from "../helpers/interfaces/DataRequest"
import { User } from "../helpers/interfaces/User"

export interface PersonalInfoProps {
  user: User
}
export interface RequestInfoProps {
  user: User
  dataRequest: DataRequest
}
export interface EducationProps {
  user: User
}
export interface ProfessionalQualificationProps {
  user: User
}

export const Column = ({
  title,
  value,
  align,
  isLink,
}: {
  title: string
  value: string
  align: string
  isLink?: boolean
}) => {
  return (
    <VStack
      alignItems={align === "left" ? "flex-start" : "flex-end"}
      spacing={0}
      width='50%'
    >
      <Text fontWeight='500' fontSize={14}>
        {title}
      </Text>
      <Text fontWeight='300' fontSize={16}>
        {isLink ? (
          <Link color='#0E62FF' href={value}>
            {value}
          </Link>
        ) : (
          value
        )}
      </Text>
    </VStack>
  )
}

export const RequestInfo: React.FC<RequestInfoProps> = ({
  user,
  dataRequest,
}) => {
  return (
    <VStack alignItems='flex-start' spacing={8} width='100%'>
      <HStack justifyContent='space-between' width='100%'>
        <Column title='Data Requested' value={dataRequest.data} align='left' />
        <Column
          title='Date Requested'
          value={moment(dataRequest.created_at).format("DD MMMM YYYY")}
          align='right'
        />
      </HStack>
      <HStack justifyContent='space-between' width='100%'>
        <Column
          title='Reason For Data Request'
          value={dataRequest.reason}
          align='left'
        />
      </HStack>
    </VStack>
  )
}

export const PersonalInfo: React.FC<PersonalInfoProps> = ({ user }) => {
  return (
    <VStack alignItems="flex-start" spacing={8} width="100%">
      <HStack justifyContent="space-between" width="100%">
        <Column
          title="First & Middle name"
          value={`${user?.first_name}`}
          align="left"
        />
        <Column title="Last name" value={`${user?.last_name}`} align="right" />
      </HStack>
      <HStack justifyContent="space-between" width="100%">
        <Column title="Phone Number" value={user?.phone} align="left" />
        <Column title="Country" value={user?.country} align="right" />
      </HStack>{" "}
      <HStack justifyContent="space-between" width="100%">
        <Column
          title="Home Address"
          value={user?.home_address}
          align="left"
        />
        <Column title="City" value={user?.city} align="right" />
      </HStack>
      <HStack justifyContent="space-between" width="100%">
        <Column
          title="Reason for Joining the Network"
          value={user?.join_reason}
          align="left"
        />
      </HStack>
    </VStack>
  )
}

export const Education: React.FC<EducationProps> = ({ user }) => {
  return (
    <VStack alignItems="flex-start" spacing={8} width="100%">
      <HStack justifyContent="space-between" width="100%">
        <Column
          title="Link To Work"
          value="www.linkedin.com"
          isLink={true}
          align="left"
        />
        <Column
          title="Name of Referee 1"
          value={user?.referee_1_name}
          align="right"
        />
      </HStack>
      <HStack justifyContent="space-between" width="100%">
        <Column
          title="Email Address of Referee 1"
          value={user?.referee_1_email}
          align="left"
        />
        <Column
          title="Relationship to Referee 1"
          value={user?.referee_1_relationship}
          align="right"
        />
      </HStack>{" "}
      <HStack justifyContent="space-between" width="100%">
        <Column
          title="Name of Referee 2"
          value={user?.referee_2_name}
          align="left"
        />
        <Column
          title="Relationship to Referee 2"
          value={user?.referee_2_relationship}
          align="right"
        />
      </HStack>
      <HStack justifyContent="space-between" width="100%">
        <Column
          title="Email Address of Referee 2"
          value={user?.referee_2_email}
          align="left"
        />
      </HStack>
    </VStack>
  )
}

export const ProfessionalQualification: React.FC<ProfessionalQualificationProps> =
  ({ user }) => {
    return (
      <VStack alignItems="flex-start" spacing={8} width="100%">
        <HStack justifyContent="space-between" width="100%">
          <Column
            title="Link to resume"
            value={user.linkedin_profile || '----'}
            isLink={!!user.linkedin_profile}
            align="left"
          />
          <Column title="Current Occupation" value={user.current_occupation || '----'} align="right" />
        </HStack>
        <HStack justifyContent="space-between" width="100%">
          <Column
            title="Organization Name"
            value={user.organization_name || '----'}
            align="left"
          />
          <Column
            title="Personal Email Address"
            value={user.personal_email || '----'}
            align="right"
          />
        </HStack>{" "}
        <HStack justifyContent="space-between" width="100%">
          <Column
            title="Professional Email Address"
            value={user.professional_email || '----'}
            align="left"
          />
          <Column title="Expertise" value={user.expertise || '----'} align="right" />
        </HStack>
      </VStack>
    )
  }
