import * as React from "react";
import {
  Box,
  Flex,
  Grid,
  Text,
  useToast,
} from "@chakra-ui/react";
import { RequestProps } from '../../pages/RequestPage';
import RequestCard from '../RequestCard';
import { NoBorderButton, RedButton, GreenButton } from '../buttons';
import { ConfirmModal } from "../modals/ConfirmModal";
import { ViewRequestModal } from "../modals/ViewRequestModal";
import { User } from '../../helpers/interfaces/User';
import HttpClient from '../../helpers/HttpClient';
import { AxiosResponse } from 'axios';


const RequestGrid: React.FC<RequestProps> = ({ users, removeFromList }) => {
  const [viewShowRequestModal, setShowViewRequestModal] = React.useState(false);
  const [showApproveModal, setShowApproveModal] = React.useState(false);
  const [showDeclineModal, setShowDeclineModal] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState<User>({} as User);
  const toast = useToast();

  const myToast = (description: string, isSuccess: boolean = true) => {
    toast({
      description,
      status: isSuccess ? "success" : "error",
      duration: 4000,
      isClosable: true,
      position: "top-right",
    });
  }

  const handleApproval = async () => {
    try{
      await HttpClient.patch<AxiosResponse<null>>('admin/users/approve/' + currentUser.id, null);
      setShowApproveModal(false);
      removeFromList(currentUser.id)
      myToast(`You have approved ${currentUser.first_name} ${currentUser.last_name}’s data request`);
    }catch(error : any){
      myToast(error?.response?.message, false);
    }
  }

  const handleDecline = async () => {
    try {
      await HttpClient.patch("admin/users/decline/" + currentUser.id, null)
      setShowDeclineModal(false)
      removeFromList(currentUser.id)
      myToast(`You have rejected ${currentUser.first_name} ${currentUser.last_name}’s data request`);
    }catch(error: any){
      console.log(error?.response);
      myToast(error?.response?.message, false);
    }
  }

  return (
    <>
      <Grid
        templateColumns='repeat(auto-fit, minmax(420px, 1fr))'
        gap={8}
        marginBottom='56px'
      >
        {users.map((user, i) => (
          <RequestCard
            key={i}
            user={user}
            removeFromList={removeFromList}
            setCurrentUser={(user: User) => setCurrentUser(user)}
            showViewRequestModal={() => setShowViewRequestModal(true)}
            showDeclineModal={() => setShowDeclineModal(true)}
            showApproveModal={() => setShowApproveModal(true)}
          />
        ))}
      </Grid>

      {/* DECLINE CONFIRMATION MODAL */}
      <ConfirmModal
        title='Decline Request'
        handleClose={() => setShowDeclineModal(false)}
        showModal={showDeclineModal}
      >
        <Box marginY={5}>
          <Text fontSize={16} fontWeight='300'>
            Are you sure you want to decline the request from
            <Text marginLeft={1} display='inline' fontWeight='500'>
              {currentUser.first_name} {currentUser.last_name}?
            </Text>
          </Text>
        </Box>
        <Flex justifyContent='space-between'>
          <NoBorderButton
            label='Cancel'
            onClick={() => setShowDeclineModal(false)}
          />
          <RedButton label='Decline' onClick={() => handleDecline()} />
        </Flex>
      </ConfirmModal>

      {/* APPROVE CONFIRMATION MODAL */}
      <ConfirmModal
        title='Approve Request'
        handleClose={() => setShowApproveModal(false)}
        showModal={showApproveModal}
      >
        <Box marginY={5}>
          <Text fontSize={16} fontWeight='300'>
            Are you sure you want to approve the request from
            <Text marginLeft={1} display='inline' fontWeight='500'>
              {currentUser.first_name} {currentUser.last_name}?
            </Text>
          </Text>
        </Box>
        <NoBorderButton
          textColor='#0E62FF'
          label='Cancel'
          onClick={() => setShowApproveModal(false)}
        />
        <GreenButton
          backgroundColor='rgba(0, 151, 61, 0.9)'
          hoverBackgroundColor='rgba(0, 151, 61, 0.8)'
          label='Approve'
          onClick={() => handleApproval()}
        />
      </ConfirmModal>

      {/* VIEW REQUEST MODAL */}
      <ViewRequestModal
        showModal={viewShowRequestModal}
        handleClose={() => setShowViewRequestModal(false)}
        user={currentUser}
      />
    </>
  )
};

export default RequestGrid;
