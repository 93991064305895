import * as React from "react"
import { Box, HStack, Text } from "@chakra-ui/layout"
import { SearchInput } from "../components/inputs"
import StatCard from "../components/cards/statCard"
import RequestGrid from "../components/lists/RequestGrid"
import Pagination from "../components/Pagination"
import HttpClient from '../helpers/HttpClient';
import { ResponseHelper } from '../helpers/interfaces/ResponseHelper';
import { User } from '../helpers/interfaces/User';
import { useLocation } from "react-router"
import { OverviewSection } from '../helpers/interfaces/OverviewSection';
import { AxiosResponse } from 'axios';
import { useDebouncedEffect } from '../effects/useDebouncedEffect';

export interface RequestProps {
  users: User[];
  removeFromList: Function;
}


const defaultStats: OverviewSection[] = [
  {
    name: "Requests Received",
    status: "rise",
    amount: "---",
    percentage: "--",
  },
  {
    name: "Approved Requests",
    status: "fall",
    amount: "---",
    percentage: "--",
  },
  {
    name: "Declined Requests",
    status: "fall",
    amount: "---",
    percentage: "--",
  },
]

const RequestPage: React.FC<RequestProps> = (prop) => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [pendingApprovalRequests, setPendingApprovalRequests] = React.useState([] as User[]);
  const [pagination, setPagination] = React.useState<any>();
  const [overview, setOverview] = React.useState<OverviewSection[]>(defaultStats);
  const location = useLocation();

  useDebouncedEffect(() => {
    (async ()=> {
      if(searchQuery.length){
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('query', searchQuery);
        let query = searchParams.toString();
        const { data } = await HttpClient.get<AxiosResponse<ResponseHelper<User[]>>>(`admin/users/pending_approval/search?${query}`);
        setPagination({ page: data.page, total: data.total, totalPages: data.lastPage})
        setPendingApprovalRequests(data.data);
      }else{
        const queryString = location.search;
        const { data } = await HttpClient.get<AxiosResponse<ResponseHelper<User[]>>>('admin/users/pending_approval' + queryString );
        setPagination({ page: data.page, total: data.total, totalPages: data.lastPage})
        setPendingApprovalRequests(data.data);
      }

    })();
  }, [searchQuery], 1000);

  React.useEffect(() => {
    (async () => {
      const queryString = location.search;
      if(queryString){
        const { data } = await HttpClient.get<AxiosResponse<ResponseHelper<User[]>>>('admin/users/pending_approval' + queryString );
        setPagination({ page: data.page, total: data.total, totalPages: data.lastPage})
        setPendingApprovalRequests(data.data);
      }
    })()
  }, [location]);
  
  React.useEffect(() => {
    (async () => {
      const { data } = await HttpClient.get<AxiosResponse<ResponseHelper<OverviewSection[]>>>('admin/overview/users');
      setOverview(data.data);
    })();
  }, []);

  const removeFromList = (userId: number) => {
    setPendingApprovalRequests(pendingApprovalRequests.filter(({id}) => id !== userId));
  }
  // console.log(overview);

  return (
    <Box marginTop="50px" paddingX={16}>
      {/* stats */}
      <HStack justifyContent="space-between">
        {overview?.map((stat) => (
          <StatCard key={stat.name} stat={stat} />
        ))}
      </HStack>

      <HStack
        justifyContent="space-between"
        marginTop="69px"
        marginBottom="36px"
      >
        <Text color="brand.primary" fontSize={18} fontWeight="500">
          All Requests
        </Text>
        <SearchInput
          placeholder="Search for a request"
          onChange={(payload) => setSearchQuery(payload)}
        />
      </HStack>

      {/* requests */}
      <RequestGrid users={pendingApprovalRequests} removeFromList={removeFromList} />
      <Box mb={4}>
        <Pagination totalResults={pagination?.total} currentPage={pagination?.page} totalPages={pagination?.totalPages} link={location.pathname} />
      </Box>
    </Box>
  )
}

export default RequestPage
