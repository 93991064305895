export const RequestIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.77654 12.4211L3.82231 14.5874C3.50659 14.7514 3.11764 14.6353 2.94359 14.325V14.325C2.89323 14.2288 2.86606 14.1223 2.86426 14.0138V4.41498C2.86426 2.58432 4.11521 1.85205 5.91536 1.85205H10.1442C11.8894 1.85205 13.1953 2.5355 13.1953 4.29294V14.0138C13.1953 14.1869 13.1265 14.353 13.0041 14.4755C12.8816 14.5979 12.7155 14.6667 12.5424 14.6667C12.4319 14.6649 12.3233 14.6378 12.2251 14.5874L8.24641 12.4211C8.0998 12.3419 7.92315 12.3419 7.77654 12.4211Z"
      stroke="#50E3C2"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.58008 7.21514H10.4435"
      stroke="#50E3C2"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AllMembersIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6807 7.27552C11.8947 7.27552 12.8787 6.29152 12.8787 5.07752C12.8787 3.86418 11.8947 2.87952 10.6807 2.87952"
      stroke="#50E3C2"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6914 9.66418C12.0541 9.68885 12.4141 9.74085 12.7694 9.81952C13.2621 9.91752 13.8554 10.1195 14.0661 10.5615C14.2007 10.8449 14.2007 11.1749 14.0661 11.4582C13.8561 11.9002 13.2621 12.1022 12.7694 12.2035"
      stroke="#50E3C2"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.39465 10.1375C8.85465 10.1375 10.956 10.5101 10.956 11.9988C10.956 13.4881 8.86798 13.8735 6.39465 13.8735C3.93465 13.8735 1.83398 13.5015 1.83398 12.0121C1.83398 10.5228 3.92132 10.1375 6.39465 10.1375Z"
      stroke="#50E3C2"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.39435 8.0125C4.77168 8.0125 3.47168 6.71184 3.47168 5.08917C3.47168 3.46717 4.77168 2.1665 6.39435 2.1665C8.01701 2.1665 9.31768 3.46717 9.31768 5.08917C9.31768 6.71184 8.01701 8.0125 6.39435 8.0125Z"
      stroke="#50E3C2"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DeactivatedIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.89 1.8335H5.11065C3.09665 1.8335 1.83398 3.2595 1.83398 5.2775V10.7228C1.83398 12.7408 3.09065 14.1668 5.11065 14.1668H10.8893C12.91 14.1668 14.1673 12.7408 14.1673 10.7228V5.2775C14.1673 3.2595 12.91 1.8335 10.89 1.8335Z"
      stroke="#50E3C2"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.12882 8.00005C7.12882 8.68138 6.57616 9.23471 5.89416 9.23471C5.21282 9.23471 4.66016 8.68138 4.66016 8.00005C4.66016 7.31871 5.21282 6.76538 5.89416 6.76538C6.57616 6.76538 7.12882 7.31871 7.12882 8.00005Z"
      stroke="#50E3C2"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.12891 8.00024H11.3409V9.23491"
      stroke="#50E3C2"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.45443 9.23442V7.99976"
      stroke="#50E3C2"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DatabankIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.91512 6.80115V11.3746"
      stroke="#50E3C2"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.02644 4.61279V11.3746"
      stroke="#50E3C2"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.087 9.2179V11.3746"
      stroke="#50E3C2"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1245 1.33337H4.87684C2.69906 1.33337 1.33398 2.87476 1.33398 5.05681V10.9433C1.33398 13.1253 2.69271 14.6667 4.87684 14.6667H11.1245C13.3086 14.6667 14.6673 13.1253 14.6673 10.9433V5.05681C14.6673 2.87476 13.3086 1.33337 11.1245 1.33337Z"
      stroke="#50E3C2"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SharedlearningIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4774 10.8156H5.66406"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4774 8.02462H5.66406"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.50073 5.24007H5.66406"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6053 1.83325C10.6053 1.83325 5.48733 1.83592 5.47933 1.83592C3.63933 1.84725 2.5 3.05792 2.5 4.90459V11.0353C2.5 12.8913 3.648 14.1066 5.504 14.1066C5.504 14.1066 10.6213 14.1046 10.63 14.1046C12.47 14.0933 13.61 12.8819 13.61 11.0353V4.90459C13.61 3.04859 12.4613 1.83325 10.6053 1.83325Z"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InnovationIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.83008 9.85427L6.8255 7.26083L9.10163 9.04878L11.0543 6.52856"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="13.3303"
      cy="2.80014"
      rx="1.28146"
      ry="1.28146"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.9492 2.08008H5.10404C3.09641 2.08008 1.85156 3.50189 1.85156 5.50952V10.8978C1.85156 12.9054 3.072 14.3211 5.10404 14.3211H10.8401C12.8477 14.3211 14.0926 12.9054 14.0926 10.8978V6.20517"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ResourcedIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2967 10.5052C14.2967 12.8907 12.891 14.2964 10.5056 14.2964H5.31481C2.92328 14.2964 1.51758 12.8907 1.51758 10.5052V5.30836C1.51758 2.92292 2.39386 1.51721 4.7793 1.51721H6.11199C6.59083 1.51721 7.04174 1.74266 7.32905 2.12574L7.93758 2.93509C8.22551 3.31742 8.67602 3.54267 9.15464 3.54362H11.0411C13.4326 3.54362 14.315 4.76068 14.315 7.1948L14.2967 10.5052Z"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.70703 9.65937H11.1027"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MessagesIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.513 6.04077L8.66941 8.33029C8.13126 8.7522 7.37689 8.7522 6.83874 8.33029L3.9707 6.04077"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.59257 2.33337H10.8778C11.7841 2.34354 12.6467 2.72666 13.2647 3.39351C13.8826 4.06036 14.2021 4.95273 14.1486 5.86279V10.2147C14.2021 11.1248 13.8826 12.0171 13.2647 12.684C12.6467 13.3508 11.7841 13.734 10.8778 13.7441H4.59257C2.64596 13.7441 1.33398 12.1605 1.33398 10.2147V5.86279C1.33398 3.91701 2.64596 2.33337 4.59257 2.33337Z"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AdminIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.86914 10.318C1.86914 10.318 1.96381 11.4767 1.98581 11.842C2.01514 12.332 2.20447 12.8793 2.52047 13.2593C2.96647 13.798 3.49181 13.988 4.19314 13.9893C5.01781 13.9907 11.0145 13.9907 11.8391 13.9893C12.5405 13.988 13.0658 13.798 13.5118 13.2593C13.8278 12.8793 14.0171 12.332 14.0471 11.842C14.0685 11.4767 14.1631 10.318 14.1631 10.318"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.66406 3.55295V3.30562C5.66406 2.49228 6.32273 1.83362 7.13606 1.83362H8.8574C9.67006 1.83362 10.3294 2.49228 10.3294 3.30562L10.3301 3.55295"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.9974 11.1188V10.2561"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.83398 5.59262V7.90395C3.11265 8.74729 4.64465 9.33795 6.32598 9.57195C6.52732 8.83795 7.18932 8.29995 7.99398 8.29995C8.78598 8.29995 9.46132 8.83795 9.64932 9.57862C11.3373 9.34462 12.8753 8.75395 14.1607 7.90395V5.59262C14.1607 4.46329 13.252 3.55396 12.1227 3.55396H3.87865C2.74932 3.55396 1.83398 4.46329 1.83398 5.59262Z"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SettingsIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8709 5.08237L13.456 4.36231C13.1049 3.75303 12.327 3.54284 11.7169 3.89244V3.89244C11.4264 4.06352 11.0799 4.11206 10.7536 4.02735C10.4274 3.94264 10.1482 3.73164 9.97773 3.44087C9.86804 3.25606 9.8091 3.04556 9.80687 2.83065V2.83065C9.81676 2.48611 9.68678 2.15223 9.44652 1.90507C9.20626 1.65792 8.87619 1.51853 8.53151 1.51868H7.6955C7.35781 1.51867 7.03405 1.65324 6.79584 1.89259C6.55763 2.13194 6.42462 2.45635 6.42624 2.79404V2.79404C6.41623 3.49124 5.84816 4.05117 5.15088 4.05109C4.93598 4.04886 4.72547 3.98992 4.54066 3.88023V3.88023C3.93055 3.53064 3.15258 3.74082 2.80153 4.3501L2.35607 5.08237C2.00544 5.69089 2.21277 6.46836 2.81984 6.8215V6.8215C3.21444 7.04932 3.45752 7.47035 3.45752 7.926C3.45752 8.38164 3.21444 8.80268 2.81984 9.0305V9.0305C2.21354 9.38125 2.00599 10.1568 2.35607 10.7635V10.7635L2.77712 11.4897C2.9416 11.7865 3.21757 12.0055 3.54397 12.0983C3.87036 12.191 4.22026 12.1499 4.51625 11.984V11.984C4.80722 11.8142 5.15396 11.7677 5.4794 11.8548C5.80483 11.9418 6.08199 12.1553 6.24928 12.4477C6.35897 12.6326 6.41791 12.8431 6.42014 13.058V13.058C6.42014 13.7623 6.99114 14.3333 7.6955 14.3333H8.53151C9.23349 14.3333 9.80351 13.766 9.80687 13.0641V13.0641C9.80524 12.7253 9.93908 12.4 10.1786 12.1604C10.4181 11.9209 10.7435 11.7871 11.0822 11.7887C11.2966 11.7944 11.5063 11.8531 11.6925 11.9596V11.9596C12.301 12.3102 13.0784 12.1029 13.4316 11.4958V11.4958L13.8709 10.7635C14.041 10.4716 14.0877 10.124 14.0006 9.79754C13.9136 9.47112 13.7 9.19286 13.4072 9.0244V9.0244C13.1143 8.85593 12.9008 8.57768 12.8137 8.25125C12.7267 7.92483 12.7733 7.57717 12.9434 7.28527C13.054 7.09218 13.2141 6.93209 13.4072 6.8215V6.8215C14.0106 6.46855 14.2174 5.69562 13.8709 5.08847V5.08847V5.08237Z"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="8.11681"
      cy="7.92602"
      r="1.75744"
      stroke="#50E3C2"
      strokeOpacity="0.8"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
