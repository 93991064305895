import * as React from "react"
import { HStack, Button, Box } from "@chakra-ui/react"
import { useHistory, useLocation } from "react-router-dom"
import queryString from "query-string"
import AllDataRequests from "./AllDataRequests"
import ApprovedDataRequests from "./ApprovedDataRequests"
import { User } from '../../helpers/interfaces/User';

export interface DataBankProps {}

const DataBank: React.FC<DataBankProps> = () => {
  const history = useHistory()
  const location = useLocation()
  const tabs = ["All Data Requests", "Approved Data Requests"]
  const [activeTab, setActiveTab] = React.useState("")

  //TODO temp
  const allUsers = [] as User[];

  React.useEffect(() => {
    const parsed: any = queryString.parse(location.search)
    setActiveTab(parsed.tab)
  }, [location])

  return (
    <Box>
      <HStack
        background='white'
        spacing={16}
        paddingX={16}
        marginBottom='50px'
        borderBottom='1px solid rgba(103, 121, 142, 0.1)'
        position='sticky'
        top={1}
        left={0}
        zIndex={2}
      >
        {tabs.map((tab, idx) => (
          <Button
            key={idx}
            variant='ghost'
            borderColor={activeTab === tab ? "#0E62FF" : "transparent"}
            borderBottomWidth='4px'
            rounded={0}
            paddingX={0}
            paddingTop='28px'
            paddingBottom='24px'
            background='transparent'
            _hover={{ background: "rgba(14, 98, 255, 0.1)" }}
            fontWeight='500'
            color={activeTab === tab ? "#0E62FF" : "rgba(23, 43, 77, 0.7)"}
            onClick={() =>
              history.push({
                pathname: `/databank`,
                search: `?tab=${tab}`,
              })
            }
            _focus={{ outline: "none" }}
          >
            {tab}
          </Button>
        ))}
      </HStack>

      {activeTab === tabs[0] && <AllDataRequests />}
      {activeTab === tabs[1] && <ApprovedDataRequests />}
    </Box>
  )
}

export default DataBank
