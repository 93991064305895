import {
    Box,
    Flex,
    Stack,
    Text,
    useToast
  } from "@chakra-ui/react";
  
  import { FormEvent, useState } from 'react';
  import { useHistory } from 'react-router-dom';
  import HttpClient from '../helpers/HttpClient';
  import { ResponseHelper } from '../helpers/interfaces/ResponseHelper';
  import { AxiosResponse } from 'axios';
  import LoginLayout from '../components/LoginLayout';
  import { WidePrimaryButton } from "../components/buttons";
  import { CombineInput } from "../components/inputs";
  
  const ResetPasswordPage: React.FC = () => {
  
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const toast = useToast();
  
  
    const myToast = (description: string, isSuccessful: boolean) => {
      toast({
        description,
        status: isSuccessful ? "success" : "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  
    const submit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if(!email){
        myToast("Please fill the email field", false);
        return;
      }
      setIsLoading(true);
      // make Api Call
      (async () => {
        try {
          await HttpClient.put<AxiosResponse<ResponseHelper<null>>>('admin/reset/password', { login_email: email });
          setIsLoading(false);
          myToast("Reset Code has been sent to your mail", true);
          history.push(`/setPassword?email=${email}`);
        } catch (error) {
          // notify user of error
          myToast(error.response.data.message, false);
          setIsLoading(false);
        }
      })()
    };
  
    return (
      <LoginLayout>
        <Flex justifyContent="space-between" align="center">
          <Box>
            <Text fontSize="7xl" color="brand.primary" fontWeight="900" style={{lineHeight: 1.2}}>Reset your<br /> password</Text>
          </Box>
          <Box bg="white" width="513px" py="50px" px="40px" border="1px" borderColor="#DEEAFA" borderRadius="10px">
            <form onSubmit={submit}>
              <Stack
                spacing={4}
              >
                <CombineInput label="Email Address" placeholder="Enter Email Address" onChange={(e) => setEmail(e.target.value)} />
                <Flex justify="flex-end">
                  <WidePrimaryButton
                    label="Reset Password"
                    height="50px"
                    onClick={() => {}}
                    isLoading={isLoading}
                    type="submit" />
                </Flex>
              </Stack>
            </form>
          </Box>
        </Flex>
      </LoginLayout>
    );
  };
  
  export default ResetPasswordPage;
  