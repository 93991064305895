import { Button, Text } from "@chakra-ui/react";
import React from "react";

export interface ButtonProps {
  isLoading?: boolean;
  p?: string;
  px?: string;
  type?: "button" | "submit" | "reset" | undefined;
  width?: any;
  label: string;
  onClick: () => void;
  backgroundColor?: string;
  textColor?: string;
  hoverBackgroundColor?: string;
  height?: string;
  borderColor?: string;
  borderWidth?: string;
  _focus?: any;
  _hover?: any;
}

export const SolidButton: React.FC<ButtonProps> = ({
  label,
  onClick,
  backgroundColor,
  hoverBackgroundColor,
  textColor = "white",
  height,
}) => {
  return (
    <Button
      _hover={{ background: hoverBackgroundColor }}
      _focus={{ outline: "none" }}
      onClick={() => onClick()}
      background={backgroundColor}
      height={height || "30px"}
      rounded="6px"
      paddingX="20px"
    >
      <Text as="span" color={textColor} fontWeight="500" fontSize={14}>
        {label}
      </Text>
    </Button>
  );
};

export const BorderButton: React.FC<ButtonProps> = ({
  label,
  onClick,
  backgroundColor,
  hoverBackgroundColor,
  textColor = "white",
  height,
  borderColor,
}) => {
  return (
    <Button
      _hover={{ background: hoverBackgroundColor }}
      _focus={{ outline: "none" }}
      borderWidth="1px"
      borderColor={borderColor}
      onClick={() => onClick()}
      background={backgroundColor}
      height={height}
      rounded="6px"
      paddingX="20px"
    >
      <Text as="span" color={textColor} fontWeight="500" fontSize={14}>
        {label}
      </Text>
    </Button>
  );
};

export const NakedButton: React.FC<ButtonProps> = ({
  label,
  onClick,
  textColor = "white",
}) => {
  return (
    <Button
      _focus={{ outline: "none" }}
      _hover={{ background: "#f3f7ff" }}
      paddingX="20px"
      onClick={() => onClick()}
      variant="ghost"
      height="30px"
    >
      <Text as="span" color={textColor} fontWeight="500" fontSize={14}>
        {label}
      </Text>
    </Button>
  );
};

const BaseButton: React.FC<ButtonProps> = ({label, onClick, textColor="white", ...rest}) => {
  const hoverBackgroundColor =  rest.hoverBackgroundColor;
  delete rest.hoverBackgroundColor;
  return (
    <Button
      type={rest.type || 'button'}
      width={rest.width || null}
      isLoading={rest.isLoading}
      _focus={{ ...rest._focus, outline: "none" }}
      _hover={{ ...rest._hover, background: hoverBackgroundColor }}
      background={rest.backgroundColor}
      paddingX="20px"
      onClick={onClick}
      height="30px"
      paddingTop="2px"
      {...rest}
      color={textColor} fontWeight="500" fontSize={14}>
        {label}
    </Button>
  );
}

// red button
export const RedButton: React.FC<ButtonProps> = (props) => {
  return <BaseButton 
  {...props} 
  textColor="#FFFFFF"
  backgroundColor="rgba(224, 24, 11, 0.9)"
  hoverBackgroundColor="rgba(224, 24, 11, 0.8)" />;
}

// red outline button 
export const RedOutlineButton: React.FC<ButtonProps> = (props) => {
  return <BaseButton 
  {...props} 
  textColor="#E0180B"
  borderColor="#E0180B"
  borderWidth="1px"
  _hover={{color: "#9c1107", borderColor: "#9c1107"}}
  backgroundColor="transparent" />;
}

// green button 
export const GreenButton: React.FC<ButtonProps> = (props) => {
  return <BaseButton 
  {...props} 
  textColor="#FFFFFF"
  backgroundColor="#00973D"
  hoverBackgroundColor="#005F26" />;
}

// green outline button
export const GreenOutlineButton: React.FC<ButtonProps> = (props) => {
  return <BaseButton 
  {...props} 
  borderColor="#00973D"
  textColor="#00973D"
  borderWidth="1px"
  backgroundColor="transparent"
  _hover={{color: "#005F26", borderColor: "#005F26"}}
  hoverBackgroundColor="transparent" />;
}

// primary button 
export const PrimaryButton: React.FC<ButtonProps> = (props) => {
  return <BaseButton 
  {...props} 
  textColor="#FFFFFF"
  backgroundColor="#0E62FF"
  hoverBackgroundColor="#0649C7" />;
}

// primary outline button
export const PrimaryOutlineButton: React.FC<ButtonProps> = (props) => {
  return <BaseButton 
  {...props} 
  textColor="#0E62FF"
  borderColor="#0E62FF"
  borderWidth="1px"
  backgroundColor="transparent"
  _hover={{color: "#0649C7", borderColor: "#0649C7"}}
  hoverBackgroundColor="transparent" />;
}


//  no border button 
export const NoBorderButton: React.FC<ButtonProps> = (props) => {
  return <BaseButton 
  {...props} 
  textColor="#0E62FF"
  backgroundColor="#FFFFFF"
  _hover={{color: "#0649C7"}}
  />;
}
export const NoBorderRedButton: React.FC<ButtonProps> = (props) => {
  return <BaseButton 
  {...props} 
  textColor="#E0180B"
  backgroundColor="#FFFFFF"
  _hover={{color: "rgba(224, 24, 11, 0.8)"}}
  />;
}

// primary button 
export const WidePrimaryButton: React.FC<ButtonProps> = ({label, onClick, textColor="white", ...rest}) => {
  return <Button
  type={rest.type || 'button'}
  width={rest.width || null}
  isLoading={rest.isLoading}
  _focus={{ ...rest._focus, outline: "none" }}
  _hover={{ ...rest._hover, background: "#0649C7" }}
  background="#0E62FF"
  paddingX="64px"
  onClick={onClick}
  height="30px"
  paddingTop="2px"
  {...rest}
  color="#FFFFFF" fontWeight="500" fontSize={14}>
    {label}
</Button>;
}

