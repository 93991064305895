import React from "react"
import { Box, Text, Flex } from "@chakra-ui/layout"
import { SearchInput } from "../components/inputs"
import Filter from "../components/filter"
import DeactivatedMembersList from "../components/lists/DeactivatedMembersList"
import Pagination from '../components/Pagination';
import { useLocation } from 'react-router';
import { useDebouncedEffect } from '../effects/useDebouncedEffect';
import HttpClient from '../helpers/HttpClient';
import { AxiosResponse } from "axios"
import { ResponseHelper } from "../helpers/interfaces/ResponseHelper"
import { User } from "../helpers/interfaces/User"
import { useEffect } from 'react';

export interface DeactivatedMembersPageProps {}

const DeactivatedMembersPage: React.FC<DeactivatedMembersPageProps> = () => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [pagination, setPagination] = React.useState<any>();
  const [declinedRequests, setDeclinedRequests] = React.useState<User[]>([] as User[]);
  const location = useLocation();

  useDebouncedEffect(() => {
    (async ()=> {
      if(searchQuery.length){
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('query', searchQuery);
        let query = searchParams.toString();
        const { data } = await HttpClient.get<AxiosResponse<ResponseHelper<User[]>>>(`admin/users/declined/search?${query}`);
        setPagination({ page: data.page, total: data.total, totalPages: data.lastPage})
        setDeclinedRequests(data.data);
      }else{
        const queryString = location.search;
        const { data } = await HttpClient.get<AxiosResponse<ResponseHelper<User[]>>>('admin/users/declined' + queryString );
        setPagination({ page: data.page, total: data.total, totalPages: data.lastPage})
        setDeclinedRequests(data.data);
      }

    })();
  }, [searchQuery], 1000);

  useEffect(() =>{
    (async () => {
      const queryString = location.search;
      if(queryString){
        const { data } = await HttpClient.get<AxiosResponse<ResponseHelper<User[]>>>('admin/users/declined' + queryString );
        setPagination({ page: data.page, total: data.total, totalPages: data.lastPage})
        setDeclinedRequests(data.data);
      }
    })();
  }, [location]);

  const removeUserFromList = (user: User) => {
    setDeclinedRequests(declinedRequests.filter((userRequest) => userRequest.id !== user.id));
  }

  return (
    <Box mb={4} mt='50px' paddingX={16}>
      <Text color='brand.primary' fontSize={18} fontWeight='500'>
        Deactivated Members
      </Text>

      <Flex
        justifyContent='space-between'
        alignItems='center'
        marginTop='30px'
        marginBottom='21px'
      >
        <SearchInput
          placeholder='Search for member'
          onChange={payload => setSearchQuery(payload)}
        />
        {/* <Filter /> */}
      </Flex>

      {/* members */}
      <DeactivatedMembersList
        users={declinedRequests}
        removeUserFromList={removeUserFromList}
      />

      <Box width='100%' background='white' paddingX={5} paddingY={5}>
        <Pagination
          totalResults={pagination?.total}
          currentPage={pagination?.page}
          totalPages={pagination?.totalPages}
          link={location.pathname}
        />
      </Box>
    </Box>
  );
}

export default DeactivatedMembersPage;
