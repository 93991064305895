import React from "react"
import { Route, useHistory } from 'react-router-dom';
import { Box, Flex } from "@chakra-ui/react"
import Sidebar from "../../components/sidebar"
import Navigation from "../../components/navigation"

function DashboardLayout({ children }: any) {
  return (
    <Flex>
      {/* side menu */}
      <Sidebar />
      {/* main */}
      <Box width="100%" background="#F9F9F9">
        {/* top header */}
        <Navigation />
        {/* content */}
        <Box>{children}</Box>
      </Box>
    </Flex>
  )
}

export const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const history = useHistory();
  const isValidToken = (token: string): boolean => {
    if(token.length < 1) return false;
    const user = JSON.parse(atob(token.split('.')[1]));
    return user.data.role === 'SUPER_USER';
  };
  const token = localStorage.getItem('token');
  if(!token || !isValidToken(token)){
    history.push('/');
  }
  
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <DashboardLayout>
            <Component {...props} />
          </DashboardLayout>
        )
      }}
    />
  )
}
