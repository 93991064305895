import { Box, HStack } from "@chakra-ui/react"
import Modal from "../modal";

export interface ConfirmProp {
  title: string;
  showModal: boolean;
  handleClose: Function;
  children?: any;
  buttons?: any[];
}

export const ConfirmModal: React.FC<ConfirmProp> = ({ title, showModal, handleClose = ()=>{}, children, buttons }) => {

    return (
        <Modal
        width="439px"
        isOpen={showModal}
        close={handleClose}
        title={title}
        closableOutside={false}
      >
        <Box marginT={5}>
          {children}
        </Box>
        {buttons?.length && <HStack justifyContent="space-between">
        {[...buttons]}
        </HStack>}
      </Modal>
    )
    
}