import * as React from "react";
import { NoBorderButton, PrimaryButton } from './buttons';
import {
  Button,
  Text,
  Box,
  HStack,
  Divider,
  // Grid,
  // GridItem,
  useOutsideClick,
} from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
// import { RadioUnselected, RadioSelected } from "./icons"
import axios from "axios"
import { CustomDropDown } from "../components/customInputs"
import { ResponseHelper } from "../helpers/interfaces/ResponseHelper"
import HttpClient from "../helpers/HttpClient"
import { AxiosResponse } from "axios"

const API_TOKEN =
  "FDshA17s4G79Ga7wBDOAgAhkTI1mJ9z8U5oGOAg8JGR48tJX6QTDnk8EWQbB9mzKe2s"
const UNIVERSAL_API_EMAIL = "dev@cchubnigeria.com"
const UNIVERSAL_API = "https://www.universal-tutorial.com"

export interface FilterProps {
  occupation: string;
  country: string;
  handleSelectCountry: any;
  handleSelectOccupation: any;
  handleFilter: () => void;
  fetchApprovedMembers: () => void;
}

const Filter: React.FC<FilterProps> = ({
  occupation,
  country,
  handleSelectCountry,
  handleSelectOccupation,
  handleFilter,
  fetchApprovedMembers,
}) => {
  const [open, setOpen] = React.useState(false);
  // const [sorting, setSorting] = React.useState("A-Z");
  const [countries, setCountries] = React.useState([]);
  const [occupations, setOccupations] = React.useState([]);

  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  useOutsideClick({
    ref: ref,
    handler: () => setOpen(false),
  });

  const fetchCountriesList = () => {
    const config = {
      headers: {
        Accept: "application/json",
        "api-token": API_TOKEN,
        "user-email": UNIVERSAL_API_EMAIL,
      },
    };

    axios
      .get(`${UNIVERSAL_API}/api/getaccesstoken`, config)
      .then(resp => {
        const authToken = resp.data.auth_token;
        const authConfig = {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };

        axios
          .get(`${UNIVERSAL_API}/api/countries`, authConfig)
          .then(({ data }) => {
            let countryOptions = data.map((country: any) => {
              let aCountry = { ...country, name: country.country_name };
              return aCountry;
            });
            setCountries(countryOptions);
          })
          .catch(error => {
            console.dir(error.response);
          });
      })
      .catch(error => {
        console.error(error.response);
      });
  };

  const fetchOccupationsList = async () => {
    const { data } = await HttpClient.get<AxiosResponse<ResponseHelper<any>>>(
      `/users/filter/dd`
    );
    let objectify = data?.data?.map((item: any, idx: any) => {
      return { id: idx, name: item };
    });
    setOccupations(objectify);
  };

  React.useEffect(() => {
    fetchCountriesList();
    fetchOccupationsList();
  }, []);

  return (
    <Box position='relative'>
      <Button
        background='white'
        rounded={5}
        border='1px solid rgba(103, 121, 142, 0.15)'
        height='45px'
        _focus={{ outline: "none" }}
        onClick={() => setOpen(!open)}
        _hover={{ background: "white" }}
      >
        <HStack fontSize={14}>
          <Text fontWeight='400' color='rgba(8, 35, 91, 0.5);'>
            Filter by:
          </Text>
          <Text fontWeight='400'>All</Text>
          <ChevronDownIcon />
        </HStack>
      </Button>
      {open && (
        <Box
          ref={ref}
          border='1px solid rgba(103, 121, 142, 0.2)'
          position='absolute'
          top={14}
          right='0'
          minW='431px'
          background='white'
          boxShadow='sm'
          rounded={3}
          zIndex={1}
        >
          {/* sort by */}
          {/* <Box padding='30px'>
            <Text fontWeight='600' fontSize={14} color='rgba(23, 43, 77, 0.7)'>
              Sort By
            </Text>
            <Grid
              marginTop={4}
              templateRows='repeat(2, 1fr)'
              templateColumns='repeat(auto-fit, minmax(150px, 1fr))'
              gap={4}
            >
              {["A-Z", "Z-A", "Most recent", "Least recent"].map(item => (
                <GridItem
                  key={item}
                  onClick={() => setSorting(item)}
                  cursor='pointer'
                >
                  <HStack>
                    {sorting === item ? <RadioSelected /> : <RadioUnselected />}
                    <Text color={sorting === item ? "#0E62FF" : "#344563"}>
                      {item}
                    </Text>
                  </HStack>
                </GridItem>
              ))}
            </Grid>
          </Box>
          <Divider /> */}
          {/* country */}
          <Box padding='30px'>
            <Text fontWeight='600' fontSize={14} color='rgba(23, 43, 77, 0.7)'>
              Country
            </Text>
            <Box marginTop={4}>
              <CustomDropDown
                required={false}
                options={countries}
                handleOption={(value: string) => handleSelectCountry(value)}
                value={country}
                width='100%'
                label=''
                placeholder='Select Country'
              />
            </Box>
          </Box>
          <Divider />
          {/* occupation */}
          <Box padding='30px'>
            <Text fontWeight='600' fontSize={14} color='rgba(23, 43, 77, 0.7)'>
              Occupation
            </Text>
            <Box marginTop={4}>
              <CustomDropDown
                required={false}
                options={occupations}
                handleOption={(value: string) => handleSelectOccupation(value)}
                value={occupation}
                width='100%'
                label=''
                placeholder='Select Occupation'
              />
            </Box>
          </Box>
          <HStack padding='30px' paddingTop={0} justifyContent='space-between'>
            <NoBorderButton
              label='Reset'
              onClick={() => {
                setOpen(false);
                fetchApprovedMembers();
                handleSelectCountry("");
                handleSelectOccupation("");
              }}
            />
            <PrimaryButton
              label='Show Result'
              onClick={() => {
                handleFilter();
                setOpen(false);
              }}
            />
          </HStack>
        </Box>
      )}
    </Box>
  );
};

export default Filter;
