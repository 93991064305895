
import Modal from '../../components/modal'
import React from 'react'
import { Box, Text, HStack, Avatar, VStack, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react'
import { User } from '../../helpers/interfaces/User';
import { PersonalInfo, Education, ProfessionalQualification } from '../modalTabContent';
import { NoBorderButton, NoBorderRedButton, GreenButton } from '../buttons';

// styles
const style = {
    tabFocusStyle: { outline: "none" },
    tabSelectedStyle: {
        borderColor: "#0E62FF",
        borderBottomWidth: "3px",
        color: "#0E62FF",
    },
    tabStyle: {
        paddingRight: 0,
        paddingLeft: 0,
        marginRight: 32,
        paddingBottom: 10,
        fontWeight: 500,
    },
    tabPanelStyle: {
        paddingRight: 0,
        paddingLeft: 0,
    }
}

export interface ViewRequestModalProps {
    user: User
    showModal: boolean;
    handleClose: Function;
}


export const ViewRequestModal: React.FC<ViewRequestModalProps> = ({ user, showModal, handleClose = () => { } }) => {

    const handleDecline = () => {
        handleClose()
    }

    const handleApprove = () => {
        handleClose()
    }

    return (
        <Modal
            width="639px"
            isOpen={showModal}
            close={() => handleClose()}
            title="Decline Request"
            closableOutside={true}
        >
            <Box marginY={5}>
                <HStack marginY={8}>
                    <Avatar
                        color="rgba(23, 43, 77, 0.5)"
                        background="rgba(23, 43, 77, 0.1)"
                        size="md"
                        name={`${user?.first_name} ${user?.last_name}`}
                    />
                    <VStack alignItems="flex-start" fontSize={15} spacing={0}>
                        <Text color="rgba(23, 43, 77, 0.9)" fontWeight="600">
                            {user?.first_name} {user?.last_name}
                        </Text>
                        <Text color="rgba(23, 43, 77, 0.7)" fontWeight="500">
                            {user?.login_email}
                        </Text>
                    </VStack>
                </HStack>
                <Tabs>
                    <TabList>
                        <Tab
                            _focus={style.tabFocusStyle}
                            _selected={style.tabSelectedStyle}
                            style={style.tabStyle}
                        >
                            Personal Info
                        </Tab>
                        <Tab
                            _focus={style.tabFocusStyle}
                            _selected={style.tabSelectedStyle}
                            style={style.tabStyle}
                        >
                            Education
                        </Tab>
                        <Tab
                            _focus={style.tabFocusStyle}
                            _selected={style.tabSelectedStyle}
                            style={style.tabStyle}
                        >
                            Professional Qualifications
                        </Tab>
                    </TabList>

                    <TabPanels paddingTop={5}>
                        <TabPanel style={style.tabPanelStyle}>
                            <PersonalInfo user={user} />
                        </TabPanel>
                        <TabPanel style={style.tabPanelStyle}>
                            <Education user={user} />
                        </TabPanel>
                        <TabPanel style={style.tabPanelStyle}>
                            <ProfessionalQualification user={user} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
            <HStack justifyContent="space-between">
                <NoBorderButton
                    label="Close"
                    onClick={() => handleClose()}
                />
                <HStack spacing={8}>
                    <NoBorderRedButton
                        label="Decline"
                        onClick={() => handleDecline()}
                    />
                    <GreenButton
                        label="Approve"
                        onClick={() => handleApprove()}
                    />
                </HStack>
            </HStack>
        </Modal>
    )
}
