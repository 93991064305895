import React from "react";
import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
    @font-face {
        font-family: 'Campton';
        src: url('Campton/Campton-ExtraBoldItalic.eot');
        src: local('Campton/Campton-ExtraBoldItalic'),
            url('Campton/Campton-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-ExtraBoldItalic.woff') format('woff'),
            url('Campton/Campton-ExtraBoldItalic.ttf') format('truetype');
        font-weight: 800;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'Campton';
        src: url('Campton/Campton-BoldItalic.eot');
        src: local('Campton/Campton-BoldItalic'),
            url('Campton/Campton-BoldItalic.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-BoldItalic.woff') format('woff'),
            url('Campton/Campton-BoldItalic.ttf') format('truetype');
        font-weight: bold;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'Campton/Campton-Book';
        src: url('Campton/Campton-Book.eot');
        src: local('Campton/Campton-Book'),
            url('Campton/Campton-Book.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-Book.woff') format('woff'),
            url('Campton/Campton-Book.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Campton';
        src: url('Campton/Campton-ExtraLight.eot');
        src: local('Campton/Campton-ExtraLight'),
            url('Campton/Campton-ExtraLight.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-ExtraLight.woff') format('woff'),
            url('Campton/Campton-ExtraLight.ttf') format('truetype');
        font-weight: 200;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Campton';
        src: url('Campton/Campton-MediumItalic.eot');
        src: local('Campton/Campton-MediumItalic'),
            url('Campton/Campton-MediumItalic.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-MediumItalic.woff') format('woff'),
            url('Campton/Campton-MediumItalic.ttf') format('truetype');
        font-weight: 500;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'Campton/Campton-BookItalic';
        src: url('Campton/Campton-BookItalic.eot');
        src: local('Campton/Campton-BookItalic'),
            url('Campton/Campton-BookItalic.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-BookItalic.woff') format('woff'),
            url('Campton/Campton-BookItalic.ttf') format('truetype');
        font-weight: normal;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'Campton';
        src: url('Campton/Campton-SemiBold.eot');
        src: local('Campton/Campton-SemiBold'),
            url('Campton/Campton-SemiBold.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-SemiBold.woff') format('woff'),
            url('Campton/Campton-SemiBold.ttf') format('truetype');
        font-weight: 600;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Campton';
        src: url('Campton/Campton-Bold.eot');
        src: local('Campton/Campton-Bold'),
            url('Campton/Campton-Bold.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-Bold.woff') format('woff'),
            url('Campton/Campton-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Campton';
        src: url('Campton/Campton-Medium.eot');
        src: local('Campton/Campton-Medium'),
            url('Campton/Campton-Medium.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-Medium.woff') format('woff'),
            url('Campton/Campton-Medium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Campton';
        src: url('Campton/Campton-SemiBoldItalic.eot');
        src: local('Campton/Campton-SemiBoldItalic'),
            url('Campton/Campton-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-SemiBoldItalic.woff') format('woff'),
            url('Campton/Campton-SemiBoldItalic.ttf') format('truetype');
        font-weight: 600;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'Campton';
        src: url('Campton/Campton-ThinItalic.eot');
        src: local('Campton/Campton-ThinItalic'),
            url('Campton/Campton-ThinItalic.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-ThinItalic.woff') format('woff'),
            url('Campton/Campton-ThinItalic.ttf') format('truetype');
        font-weight: 100;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'Campton';
        src: url('Campton/Campton-Light.eot');
        src: local('Campton/Campton-Light'),
            url('Campton/Campton-Light.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-Light.woff') format('woff'),
            url('Campton/Campton-Light.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Campton';
        src: url('Campton/Campton-Black.eot');
        src: local('Campton/Campton-Black'),
            url('Campton/Campton-Black.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-Black.woff') format('woff'),
            url('Campton/Campton-Black.ttf') format('truetype');
        font-weight: 900;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Campton';
        src: url('Campton/Campton-ExtraLightItalic.eot');
        src: local('Campton/Campton-ExtraLightItalic'),
            url('Campton/Campton-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-ExtraLightItalic.woff') format('woff'),
            url('Campton/Campton-ExtraLightItalic.ttf') format('truetype');
        font-weight: 200;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'Campton';
        src: url('Campton/Campton-ExtraBold.eot');
        src: local('Campton/Campton-ExtraBold'),
            url('Campton/Campton-ExtraBold.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-ExtraBold.woff') format('woff'),
            url('Campton/Campton-ExtraBold.ttf') format('truetype');
        font-weight: 800;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Campton';
        src: url('Campton/Campton-Thin.eot');
        src: local('Campton/Campton-Thin'),
            url('Campton/Campton-Thin.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-Thin.woff') format('woff'),
            url('Campton/Campton-Thin.ttf') format('truetype');
        font-weight: 100;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Campton';
        src: url('Campton/Campton-BlackItalic.eot');
        src: local('Campton/Campton-BlackItalic'),
            url('Campton/Campton-BlackItalic.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-BlackItalic.woff') format('woff'),
            url('Campton/Campton-BlackItalic.ttf') format('truetype');
        font-weight: 900;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'Campton';
        src: url('Campton/Campton-LightItalic.eot');
        src: local('Campton/Campton-LightItalic'),
            url('Campton/Campton-LightItalic.eot?#iefix') format('embedded-opentype'),
            url('Campton/Campton-LightItalic.woff') format('woff'),
            url('Campton/Campton-LightItalic.ttf') format('truetype');
        font-weight: 300;
        font-style: italic;
    }
    
    
      `}
  />
);

export default Fonts;
