import * as React from "react"
import { Box, HStack, Text } from "@chakra-ui/react"
import { CloseIcon } from "./icons"

export interface ModalProps {
  width: string | number
  title: string
  isOpen: boolean
  close: Function
  closableOutside: boolean
}

const Modal: React.FC<ModalProps> = ({
  width,
  title,
  isOpen,
  close,
  children,
  closableOutside,
}) => {
  return (
    <>
      {isOpen && (
        <>
          <Box
            position="fixed"
            zIndex={3}
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            rounded={8}
            width={width}
            background="white"
            padding="30px"
          >
            <HStack justifyContent="space-between" marginBottom={5}>
              <Text
                color="rgba(23, 43, 77, 0.9)"
                fontWeight="500"
                fontSize={20}
              >
                {title}
              </Text>
              <Box onClick={() => close()} cursor="pointer">
                <CloseIcon />
              </Box>
            </HStack>
            {children}
          </Box>

          <Box
            onClick={() => closableOutside && close()}
            background="rgba(8, 35, 91, 0.5);"
            style={{ backdropFilter: "blur(18px)" }}
            height="100vh"
            width="100vw"
            position="fixed"
            zIndex={2}
            top={0}
            left={0}
          ></Box>
        </>
      )}
    </>
  )
}

export default Modal
