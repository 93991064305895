import React from "react"
import {
  Flex,
  Heading,
  HStack,
  Box,
  Text,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { useLocation, useHistory } from 'react-router-dom';
import menus from "./sidebar/menus"
import { NotificationBell } from "./icons"
import { AuthUser } from '../helpers/AuthUser';
const environ = process.env.NODE_ENV

export interface NavigationProps {}

const Navigation: React.FC<NavigationProps> = () => {
  const location = useLocation()
  const history = useHistory()
  const [pageTitle, setPageTitle] = React.useState("")
  const [appUrl, setAppUrl] = React.useState("")
  const user = AuthUser.details()

  // set appropriate page title
  React.useEffect(() => {
    menus.map(menu => {
      let findPath: any = menu.paths.find(path => {
        return path.link === location.pathname
      })

      if (findPath) {
        setPageTitle(findPath.name)
      }
      return findPath
    })
  }, [location])

  React.useEffect(() => {
    environ === "production"
      ? setAppUrl("https://api.adein.cchub.org")
      : setAppUrl("https://api.adein.cchub.xyz")
  }, [])

  const logout = (): void => {
    localStorage.removeItem("token")
    history.push("/")
  }

  return (
    <HStack
      position='sticky'
      top={0}
      left={0}
      zIndex={2}
      background='white'
      minHeight='61px'
      borderBottom='1px solid rgba(103, 121, 142, 0.1)'
    >
      <Flex
        width='100%'
        paddingX={16}
        justifyContent='space-between'
        alignItems='center'
      >
        <Heading fontSize={15} fontWeight='500' color='#08235B'>
          {pageTitle}
        </Heading>
        <Flex alignItems='center'>
          <Box marginRight={8}>
            <NotificationBell />
          </Box>
          <Menu autoSelect={false} placement='bottom-end'>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              rounded='100px'
              background='rgba(8, 35, 91, 0.08)'
              display='flex'
              _focus={{ outline: "none" }}
              height='38px'
              padding='7px'
            >
              <HStack spacing={3}>
                <Avatar
                  name={`${user?.first_name} ${user?.last_name}`}
                  src={`${appUrl}/${user?.avatar}`}
                  size='xs'
                />
                <Text fontSize={14} color='#08235B' fontWeight='400'>
                  {user?.first_name} {user?.last_name}
                </Text>
              </HStack>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={logout}>Log Out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </HStack>
  )
}

export default Navigation
