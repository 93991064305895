import { User } from './interfaces/User';

export class AuthUser {

    static details(): User | null {
        const token: string | null = localStorage.getItem('token');
        if(token){
            const user: User = JSON.parse(atob(token.split(".")[1])).data;
            return user;
        }
        return null;
    }

    // static detail(field: string) {
    //     const user: User | null = AuthUser.details();

    //     return user? user[field ]: null;
    // }
}
