import React from "react"
import {
  Box,
  Link,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  HStack,
  Avatar,
  Button,
  Flex,
  Divider,
  VStack,
  useToast,
} from "@chakra-ui/react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { NavLink } from "react-router-dom"
import { NakedButton, PrimaryButton, RedOutlineButton, RedButton, GreenButton, GreenOutlineButton } from './buttons';
import { useHistory, useLocation } from "react-router-dom"
import queryString from "query-string"
import { User } from '../helpers/interfaces/User';
import Row from './Row';
import Activities from "./Activities"
import { ConfirmModal } from "./modals/ConfirmModal"
import ViewActivityModal from './modals/ViewActivityModal';
import HttpClient from '../helpers/HttpClient';
const environ = process.env.NODE_ENV
export interface UserProfileContentProps {
  reactivate: boolean;
  deactivate: boolean;
  sendMessage: boolean;
  route: string;
  routeName: string;
  user?: User;
}

const UserProfileContent: React.FC<UserProfileContentProps> = ({
  reactivate,
  deactivate,
  sendMessage,
  routeName,
  route,
  user
}) => {
  const toast = useToast()
  const history = useHistory()
  const location = useLocation()
  const tabs = [
    "Personal Information",
    "Educational Background",
    "Professional Qualifications",
  ]
  const [activeTab, setActiveTab] = React.useState("")
  const [appUrl, setAppUrl] = React.useState("")

  // modals
  const [showReactivateModal, setShowReactivateModal] = React.useState(false)
  const [showDeactivateModal, setShowDeactivateModal] = React.useState(false)
  const [viewActivityRequest, setViewActivityRequest] = React.useState(false)

  const myToast = (description: string, isSuccess: boolean = true) => {
    toast({
      description,
      status: isSuccess ? "success" : "error",
      duration: 4000,
      isClosable: true,
      position: "top-right",
    })
  }
  const handleReactivation = async (user: User) => {
    try{
      await HttpClient.patch(`/admin/users/approve/${user?.id}`, null);
      setShowReactivateModal(false);
      myToast(`You have activated ${user.first_name} ${user.last_name}`);
    }catch(error){
      console.log(error)
      myToast(error.response.data.message, false);
    }
    // history.push("/deactivated-members")
  }

  const handleDecline = async (user?: User) => {
    // users/decline/:id
    try{
      await HttpClient.patch(`admin/users/decline/${user?.id}`, null);
      myToast(`${user?.first_name} ${user?.last_name} declined`);
      setShowDeactivateModal(false);
    }catch(error) {
      // error.response.data
      console.log(error.response.data);
      myToast(error.response.data.message, false);
    }
  }

  React.useEffect(() => {
    environ === "production"
      ? setAppUrl("https://api.adein.cchub.org")
      : setAppUrl("https://api.adein.cchub.xyz")
  }, [])

  React.useEffect(() => {
    const parsed: any = queryString.parse(location.search)
    setActiveTab(parsed.tab)
  }, [location])

  return (
    <>
      <Box marginTop='30px' paddingBottom='100px' paddingX={16}>
        <Breadcrumb
          spacing='8px'
          separator={<ChevronRightIcon color='rgba(8, 35, 91, 0.7)' />}
          marginBottom='50px'
        >
          <BreadcrumbItem>
            <Link as={NavLink} to={`/${route}`} color='#0E62FF'>
              {routeName}
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text color='rgba(8, 35, 91, 0.7)'>User's Profile</Text>
          </BreadcrumbItem>
        </Breadcrumb>

        <HStack
          justifyContent='space-between'
          height='112px'
          background='white'
          borderRadius='5px 5px 0px 0px'
          border='1px solid rgba(103, 121, 142, 0.1)'
          paddingX='39px'
        >
          <HStack spacing={3}>
            <Avatar
              size='md'
              name={`${user?.first_name} ${user?.last_name}`}
              background='rgba(23, 43, 77, 0.1)'
              color='rgba(23, 43, 77, 0.5)'
              src={`${appUrl}/${user?.avatar}`}
            />
            <Text fontWeight='600' fontSize={20} color='#172B4D'>
              {user?.first_name} {user?.last_name}
            </Text>
          </HStack>
          <HStack spacing={5}>
            {deactivate && (
              <RedOutlineButton
                height='40px'
                backgroundColor='transparent'
                textColor='#E0180B'
                label='Deactivate Member'
                onClick={() => setShowDeactivateModal(true)}
              />
            )}
            {sendMessage && (
              <PrimaryButton
                height='40px'
                label='Send Message'
                onClick={() => alert()}
              />
            )}
            {reactivate && (
              <GreenOutlineButton
                height='40px'
                label='Reactivate Member'
                onClick={() => setShowReactivateModal(true)}
              />
            )}
          </HStack>
        </HStack>
        <HStack
          background='white'
          spacing={10}
          paddingX='40px'
          marginBottom='40px'
        >
          {tabs.map((tab, index) => (
            <Button
              key={index}
              variant='ghost'
              borderColor={activeTab === tab ? "#0E62FF" : "transparent"}
              borderBottomWidth='4px'
              rounded={0}
              paddingX={0}
              paddingTop='28px'
              paddingBottom='24px'
              background='transparent'
              _hover={{ background: "rgba(14, 98, 255, 0.1)" }}
              fontWeight='500'
              color={activeTab === tab ? "#0E62FF" : "rgba(23, 43, 77, 0.7)"}
              onClick={() =>
                history.push({
                  pathname: `/${route}/${user?.id}`,
                  search: `?tab=${tab}`,
                })
              }
              _focus={{ outline: "none" }}
            >
              {tab}
            </Button>
          ))}
        </HStack>

        <Flex justifyContent='space-between'>
          <Box
            background='white'
            width='62%'
            border='1px solid rgba(103, 121, 142, 0.1);'
            rounded='5px'
          >
            <Text
              paddingY='27px'
              paddingX='50px'
              fontWeight='500'
              fontSize={16}
              color='rgba(23, 43, 77, 0.9)'
            >
              {activeTab}
            </Text>
            <Divider />
            <Box paddingX='50px' paddingY='30px'>
              {/* personal info */}
              {activeTab === tabs[0] && (
                <VStack alignItems='flex-start' width='100%' spacing={8}>
                  <Row title='First & Middle Name' value={user?.first_name!} />
                  <Row title='Last Name' value={user?.last_name!} />
                  <Row title='Phone Number' value={user?.phone!} />
                  <Row title='Email Address' value={user?.login_email!} />
                  <Divider />
                  <Row title='Country' value={user?.country!} />
                  <Row title='City' value={user?.city!} />
                  <Row title='Home Address' value={user?.home_address!} />
                  <Divider />
                  <Row
                    title='Surveillance Visit Date'
                    value="When you register for one of our online events, you'll receive a reminder."
                  />
                </VStack>
              )}
              {/* Educational Background */}
              {activeTab === tabs[1] && (
                <VStack alignItems='flex-start' width='100%' spacing={8}>
                  <Row
                    title='Link To Work'
                    value={user?.linkedin_profile!}
                    link={user?.linkedin_profile!}
                  />
                  <Row
                    title='Name of Referee 1'
                    value={user?.referee_1_name!}
                  />
                  <Row
                    title='Email Address of Referee 1'
                    value={user?.referee_1_email!}
                  />
                  <Row
                    title='Relationship to Referee 1'
                    value={user?.referee_1_relationship!}
                  />
                  <Divider />
                  <Row
                    title='Name of Referee 2'
                    value={user?.referee_2_name!}
                  />
                  <Row
                    title='Email Address of Referee 2'
                    value={user?.referee_2_email!}
                  />
                  <Row
                    title='Relationship to Referee 2'
                    value={user?.referee_2_relationship!}
                  />
                </VStack>
              )}
              {/* professional qualifications */}
              {activeTab === tabs[2] && (
                <VStack alignItems='flex-start' width='100%' spacing={8}>
                  <Row
                    title='Link To Resume'
                    value={user?.linkedin_profile!}
                    link={user?.linkedin_profile!}
                  />
                  <Row
                    title='Current Occupation'
                    value={user?.current_occupation!}
                  />
                  <Row
                    title='Personal Email Address'
                    value={user?.professional_email!}
                  />
                  <Row
                    title='Organization Name'
                    value={user?.organization_name!}
                  />
                  <Divider />
                  <Row title='Expertise' value={user?.expertise!} />
                  {/* <Row
                    title="Email Address of Referee 2"
                    value="Ogunbadejolateef@gmail.com"
                  />
                  <Row
                    title="Professional Email Address"
                    value="Busayooladejo@cchub.rw"
                  /> */}
                </VStack>
              )}
            </Box>
          </Box>
          {/* activities */}
          <Activities
            user={user}
            onClickView={() => setViewActivityRequest(true)}
          />
        </Flex>
      </Box>

      {/* // modals */}
      {/* DEACTIVATE MODAL */}
      <ConfirmModal
        title='Deactivate Member'
        handleClose={() => setShowDeactivateModal(false)}
        showModal={showDeactivateModal}
      >
        <Box marginY={5}>
          <Text fontSize={16} fontWeight='300'>
            Are you sure you want deactivate
            <Text as='span' marginLeft={1} display='inline' fontWeight='500'>
              {user?.first_name} {user?.last_name}?
            </Text>
          </Text>
        </Box>
        <HStack justifyContent='space-between'>
          <NakedButton
            textColor='#0E62FF'
            label='Cancel'
            onClick={() => setShowDeactivateModal(false)}
          />
          <RedButton label='Decline' onClick={() => handleDecline(user)} />
        </HStack>
      </ConfirmModal>

      {/* REACTIVATE MODAL */}
      <ConfirmModal
        title='Reactivate Member'
        handleClose={() => setShowReactivateModal(false)}
        showModal={showReactivateModal}
      >
        <Box marginY={5}>
          <Text fontSize={16} fontWeight='300'>
            Are you sure you want reactivate
            <Text as='span' marginLeft={1} display='inline' fontWeight='500'>
              {user?.first_name} {user?.last_name}?
            </Text>
          </Text>
        </Box>
        <HStack justifyContent='space-between'>
          <NakedButton
            textColor='#0E62FF'
            label='Cancel'
            onClick={() => setShowReactivateModal(false)}
          />
          <GreenButton
            label='Activate'
            onClick={() => handleReactivation(user!)}
          />
        </HStack>
      </ConfirmModal>

      {/* activity request */}
      <ViewActivityModal
        showModal={viewActivityRequest}
        handleClose={() => setViewActivityRequest(false)}
      />
    </>
  )
}

export default UserProfileContent
